import axios from 'axios'
var convert = require('xml-to-json-promise')

class OggettiCatastaliApi {
  async fetchOggettiCatastali (idRiga, idSoggetto, flagAssociato) {
    console.log('<OggettiCatastaliApi.fetchOggettiCatastali> ') // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'TF.AIR.A.01',
        idRiga: idRiga,
        idSoggetto: idSoggetto,
        primaChiamata: 'S',
        flAssociato: flagAssociato,
        variante: '99'
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    console.log('<RiversamentiApi.fetchOggettiCatastali> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)

    /* remove comment */
    var data = result.data
    const iStart = result.data.indexOf('<!--')
    const iEnd = result.data.indexOf('-->')
    if (iEnd > iStart) {
      data = result.data.substring(iEnd + 4, result.data.length)
    }

    /* conver data to json */
    return JSON.parse(data)
  }

  async pushInserimentoMultiplo (idRiga, idSoggetto, immobiliList) {
    console.log('<OggettiCatastaliApi.pushInserimentoMultiplo> ') // eslint-disable-line

    var params = new URLSearchParams()
    params.append('tipo_operazione', 'AO.UIDE.I.03')
    params.append('idRiga', idRiga)
    params.append('idSoggetto', idSoggetto)
    params.append('soloRigaCorrente', 'U')
    params.append('xmlOutPut', 'S')

    immobiliList.forEach(element => {
      console.log('<RiversamentiApi.pushInserimentoMultiplo> immobiliList->element: ', element) // eslint-disable-line
      params.append('imm', element.imm)
    })

    const request = {
      params: params
    }
    console.log('<RiversamentiApi.pushInserimentoMultiplo> request: ', request) // eslint-disable-line
    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    console.log('<RiversamentiApi.pushInserimentoMultiplo> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)

    let jsonresult = {}
    await convert.xmlDataToJSON(result.data).then(json => {
      if (json.PAGER.GENERAHTML[0]) {
        jsonresult = {
          tipo_operazione: json.PAGER.$.tipo_operazione,
          ...json.PAGER.GENERAHTML[0].$
        }
      }
    })
    return jsonresult
  }
}

export default new OggettiCatastaliApi()
