<template>
  <div ref="pgrid" :options="options"></div>
</template>
<script>
export default {
  name: 'GstPgrid',
  props: {
    options: { type: Object, required: true },
    clipboard: { type: Boolean, required: false, default: false },
    autoAlign: { type: Boolean, required: false, default: false },
    autoHorizontalScroll: { type: Boolean, required: false, default: false }
  },
  data: function () {
    return {
      countColumnView: 0,
      loptions: null,
      grid: null
      // select: null
    }
  },
  mounted: function () {
    console.log('GstPgrid :: mounted') // eslint-disable-line     
    this.loptions = this.options
    if (this.options.height === undefined) {
      this.loptions = {
        ...this.options,
        height: 500
      }
    }

    if (this.clipboard) {
      this.installClipboard()
    }

    if (this.loptions.colModel && this.autoAlign) {
      this.setLeftColString()
    }

    if (this.autoHorizontalScroll) {
      this.setAutoHorizontalScroll()
    }

    // eslint-disable-next-line no-undef
    this.grid = pq.grid(this.$el, this.loptions)
    this.pager = this.grid.pager()
    // this.select = this.grid.select()
    console.log('GstPgrid :: mounted :: pager -> ', this.pager) // eslint-disable-line     
    // console.log('GstPgrid :: mounted :: select -> ', this.select) // eslint-disable-line
  },

  methods: {
    pager: function () {
      return this.pager
    },

    refreshDataAndView: function () {
      this.grid.refreshDataAndView()
    },
    refreshView: function () {
      this.grid.refreshView()
    },
    refresh: function () {
      this.grid.refresh()
    },
    refreshHeader: function () {
      this.grid.refreshHeader()
    },
    refreshRows: function (object) {
      this.grid.refreshRow(object)
    },
    loadState: function () {
      this.grid.loadState()
    },
    setData: function (data) {
      this.grid.option('dataModel.data', data)
      this.grid.refreshView()
    },
    filter: function (filter) {
      this.grid.filter(filter)
    },
    clearFilters: function () {
      this.grid.reset({ filter: true })
    },
    reset: function (object) {
      this.grid.reset(object)
    },
    getGrid: function () {
      return this.grid
    },
    copySelect: function () {
      var select = this.grid.Selection()
      var arr = select.getSelection()
      if (arr.length > 0) {
        var value = this.grid.getCell({
          rowIndx: arr[0].rowIndx,
          dataIndx: arr[0].dataIndx
        })
        this.copyToClipoard(value.context.innerHTML)
      }
    },
    copyToClipoard (value) {
      const el = document.createElement('textarea')
      el.value = value
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    ctrlCopy: function (event) {
      console.log('GstPGrid :: ctrlCopy', event) // eslint-disable-line     
      this.copySelect()
    },
    installClipboard: function () {
      var self = this
      this.loptions.contextMenu = {
        on: true,
        head: true,
        items: function (evt, ui) {
          if (!ui.$th) {
            return [
              {
                name: 'Copia',
                action: function (evt, ui, item) {
                  self.copySelect()
                }
              }
            ]
          }
        }
      }
    },
    setLeftColString: function () {
      this.loptions.colModel.forEach(col => {
        if (col.colModel) {
          col.colModel.forEach(subCol => {
            if (subCol.dataType === 'string' && !subCol.ignore) {
              subCol.align = 'left'
            } else if ((subCol.dataType === 'integer' || subCol.dataType === 'float') && !subCol.ignore) {
              subCol.align = 'right'
            }
          })
        }
        if (col.dataType === 'string' && !col.ignore) {
          col.align = 'left'
        } else if ((col.dataType === 'integer' || col.dataType === 'float') && !col.ignore) {
          col.align = 'right'
        }
      })
    },
    setAutoHorizontalScroll: function () {
      var self = this
      this.loptions.beforeHideCols = function (evt, ui) {
        if (ui.diShow.length === 1) {
          self.countColumnView++
        } else if (ui.diHide.length === 1) {
          self.countColumnView--
        }
        if (self.countColumnView >= 1) {
          self.grid.option('scrollModel.autoFit', false)
          self.grid.option('scrollModel.flexContent', false)
          self.grid.option('scrollModel.horizontal', true)
        } else {
          self.grid.option('scrollModel.autoFit', true)
          self.grid.option('scrollModel.flexContent', true)
          self.grid.option('scrollModel.horizontal', false)
        }
      }
    }
  }
}
</script>
<!--[if IE]>
<style>
  .pq-slider-icon {
    top: 3px !important;
  }
</style>
<![endif]-->
<style>
.pq-grid-cell,
.pq-grid-row {
  border-color: #cbcbcb;
}
.pq-grid-col,
.pq-grid-cell {
  line-height: 1.5em;
}
.pq-slider-icon {
  right: 0px !important;
  top: 3px !important;
}
.pq-grid-title,
.pq-group-header,
.pq-grid-header-table.pq-td-div {
  color: #e2e2e8;
  font-weight: bold;
}
.pq-grid-title,
.pq-group-header {
  background: #336699 !important;
}
.pq-toolbar,
.pq-group-header.ui-helper-clearfix.ui-sortable,
.pq-body-outer {
  background: #cfcfdc !important;
}
.pq-grid-title,
.pq-group-header {
  border-color: #336699 !important;
}
.pq-grid-title {
  line-height: 1.3em;
  padding-left: 5px;
  padding-right: 0px;
}
.select,
.pq-grid .pq-grid-row.ui-state-highlight {
  background: #f5e9ad !important;
  color: #2e83ff;
  /*color: #339;*/
}
.ui-state-hover {
  background: #dce1ec !important;
}
.pq-group-placeholder {
  color: #8b8b8b !important;
}
.pq-group-item,
button.ui-button.ui-corner-all.ui-widget {
  background: #dcd9de !important;
}
.pq-group-item {
  color: #555555;
}
/* custom row color
  .pq-grid-row {
    background: #e2e2e8 !important;
  }
  */
/* custom row striped color
  .pq-grid-row.pq-striped {
    background: #cfcfdc !important;
  }
  */
.pq-td-border-right > .pq-grid-row > .pq-grid-cell {
  border-right-color: #faf9f9;
}
div.pq-loading-mask {
  width: 120px !important;
  left: 48% !important;
}
.ui-menu .ui-menu-item-wrapper {
  padding: 3px 9px 3px 5px !important;
}
button.ui-button.ui-corner-all.ui-widget:focus {
  outline: 1px auto -webkit-focus-ring-color !important;
}
</style>
