<template>
  <div>
    <b-modal centered :hide-header="hideHeader" :hide-footer="hideFooter" size="sm" :no-close-on-backdrop="noCloseOnBackdrop" :no-close-on-esc="noCloseOnEsc" v-model="show">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <div style="margin-left:15px; margin-top:5px;">
              {{message}}
              <slot></slot>
            </div>
          </div>
          <div style="margin-top: 5px; text-align: center; width: 100%">
            <gst-button width="150px" title="Chiudi" text="Chiudi" icon="close" @click="close">
            </gst-button>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'GstMessage',
  props: {
    show: { type: Boolean, required: true },
    message: { type: String, required: true }
  },
  data () {
    return {
      hideHeader: true,
      hideFooter: true,
      noCloseOnEsc: false,
      noCloseOnBackdrop: true,
      timeout: null
    }
  },
  watch: {
  },
  created () {
    this.show = this.show || false
    console.log('GstMessage :: created :: show', this.show) // eslint-disable-line     
  },
  methods: {
    close: function () {
      this.show = false
      this.$emit('close', this.show)
    }
  }
}
</script>

<style scoped>
</style>
