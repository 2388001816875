
export class TabData {
  constructor () {
    this.id = ''
    this.text = ''
    this.div = ''
    this.isClosable = true
    this.component = ''
    this.params = ''
  }
}

export const GST_EVENT_CLOSE_CURRENT_TAB = 'gst_event_close_current_tab'
export const GST_EVENT_CHANGE_CURRENT_TAB = 'gst_event_change_current_tab'
export const GST_EVENT_CLOSE_TAB = 'gst_event_close_tab'
export const GST_EVENT_CLOSE_DIALOG = 'gst_event_close_dialog'
export const GST_EVENT_OPEN_DIALOG = 'gst_event_open_dialog'
export const GST_EVENT_RESULT_DIALOG = 'gst_event_result_dialog'
export const GST_EVENT_OPEN_FRAME_TAB = 'gst_event_open_frame_tab'
export const GST_EVENT_OPEN_TAB = 'gst_event_open_tab'
export const GST_EVENT_STOP_LOADER = 'gst_event_stop_loader'
export const GST_EVENT_LOADER = 'gst_event_loader'
export const GST_EVENT_PUSH_MESSAGE = 'gst_event_push_message'
export const GST_EVENT_UPDATE_ENTE = 'gst_event_update_ente'
