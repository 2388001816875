<template>
  <div class="gst-label" :style="componentStyle">
    <label v-if="label !==''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <label class="gst-label-value" :style="valueStyle">
      <template v-if="link">
        <a href="#" @click="click()">
          <span v-html="value" />
        </a>
      </template>
      <template v-else>
        <span v-html="value" />
      </template>
    </label>
  </div>
</template>

<script>
export default {
  name: 'GstLabel',
  props: {
    label: { type: String, required: false, default: '' },
    value: { type: String, required: true },
    labelwidth: { type: String, required: false, default: 'auto' },
    valueWidth: { type: String, required: false, default: 'auto' },
    styletype: { type: String, required: false, default: 'inline' },
    labelbold: { type: Boolean, required: false, default: false },
    link: { type: Boolean, required: false, default: false }
  },
  styletype: {
    validator: function (value) {
      return ['block', 'inline'].indexOf(value) !== -1
    }
  },
  computed: {
    componentStyle () {
      return 'display: ' + ((this.styletype) === 'inline' ? 'inline-block' : 'block') + ';'
    },
    labelClass () {
      return (this.styletype === 'inline') ? 'gst-input-label-inline' : 'gst-input-label'
    },
    labelStyle () {
      return 'width: ' + this.labelwidth +
      'display: ' + (this.styletype === 'inline' ? 'inline-block' : 'block') + ';' +
      'font-weight: ' + (this.labelbold ? 'bold' : 'normal') + ';'
    },
    valueStyle () {
      return 'width: ' + this.valueWidth +
      'display: ' + (this.styletype === 'inline' ? 'inline-block' : 'block') + ';' +
      'text-decoration: ' + (this.link) ? 'underline' : 'none' + ';'
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
