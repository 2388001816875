import axios from 'axios'
const convertXml = require('xml-js')
class Http {
  get (baseUrl, request, config) {
    return axios.get(baseUrl, request, config).then(result => {
      var data
      console.log('<Http::get> headers', result.headers)  // eslint-disable-line
      if (result.data && result.headers['content-type'] === 'text/xml' && config.responseType === 'json') {
        console.log('<Http::get> parsing', result.headers['content-type'], 'to json')  // eslint-disable-line
        data = convertXml.xml2json(result.data, { compact: true, spaces: 4 })
        result.data = JSON.parse(data)
        console.log('<Http::get> response parsed:', result)  // eslint-disable-line
      } else if (result.data && (typeof result.data === 'string' || result.data instanceof String)) {
        console.log('<Http::get> parsing', result.headers['content-type'], 'to json')  // eslint-disable-line
        /* remove comment */
        data = result.data
        const iStart = result.data.indexOf('<!--')
        const iEnd = result.data.indexOf('-->')
        if (iStart !== -1 && iEnd !== -1 && iEnd > iStart) {
          data = result.data.substring(iEnd + 4)
        }

        /* remove possible escape characters */
        // data = data.replace(/"/g, "'")
        data = data.replace(/\\/g, '')
        console.log('<Http::get> data:', data)  // eslint-disable-line

        /* conver data to json */
        result.data = JSON.parse(data)

        console.log('<Http::get> response parsed:', result)  // eslint-disable-line
      } else {
        console.log('<Http::get> response:', result)  // eslint-disable-line
      }
      return result
    })
  }

  getRaw (baseUrl, request, config) {
    return axios.get(baseUrl, request, config)
  }

  getRaw (baseUrl, config) {
    return axios.get(baseUrl, config)
  }

  post (baseUrl, request, config) {
    return axios.post(baseUrl, request, config).then(result => {
      console.log('<Http::post> response:', result)  // eslint-disable-line
      return result
    })
  }

  put (baseUrl, request, config) {
    return axios.put(baseUrl, request, config).then(result => {
      console.log('<Http::put> response:', result)  // eslint-disable-line
      return result
    })
  }


  all (promises) {
    return axios.all(promises).then(results => {
      console.log('<Http::all> response:', results)  // eslint-disable-line
      return results
    })
  }
}

export default new Http()
