<template>
  <div class="gst-textarea">
    <label v-if="label !==''" class="gst-input-label gst-label-textarea" :style="labelStyle">
      <span v-html="label" />
    </label>
    <textarea
      class="gst-textarea-input"
      :style="inputStyle"
      :disabled="disable"
      @change="changeEvent($event)"
      @keypress="keypressEvent"
      @keydown="keydownEvent"
      @keyup="keyupEvent"
      :placeholder="placeholder"
      v-model="value"
    />
  </div>
</template>

<script>
export default {
  name: 'GstTextarea',
  props: {
    value: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    rows: { type: Number, required: false },
    cols: { type: Number, required: false },
    styletype: { type: String, required: true },
    labelbold: { type: Boolean, required: false, default: false },
    labelwidth: { type: String, required: false, default: 'auto' },
    inputwidth: { type: String, required: false, default: 'auto' },
    inputheight: { type: String, required: false, default: 'auto' },
    placeholder: { type: String, required: false },
    disable: { type: Boolean, required: false }
  },
  styletype: {
    validator: function (value) {
      return ['block', 'inline'].indexOf(value) !== -1
    }
  },
  computed: {
    labelStyle () {
      return (
        'width: ' +
        this.labelwidth +
        ';' +
        'display: ' +
        (this.styletype === 'inline' ? 'inline-block' : this.styletype) +
        ';' +
        'font-weight: ' +
        (this.labelbold ? 'bold' : 'normal') +
        ';' +
        'vertical-align: top;'
      )
    },
    inputStyle () {
      return (
        'width:' +
        this.inputwidth +
        '; height:' +
        this.inputheight +
        '; display: ' +
        (this.styletype === 'inline' ? 'inline-block' : this.styletype) +
        ';'
      )
    }
  },
  methods: {
    changeEvent (event) {
      this.$emit('input', event.target.value)
    },
    keypressEvent (event) {
      this.$emit('keypress', event)
    },
    keydownEvent (event) {
      this.$emit('keydown', event)
    },
    keyupEvent (event) {
      this.$emit('keyup', event)
    }
  }
}
</script>

<style scoped>
@import "../../assets/styles/gst.input.style.css";
label.gst-input-label.gst-label-textarea {
  padding-top: 6px;
}
</style>
