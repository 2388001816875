<template>
    <div class="gst-multiselect-group">
      <multiselect v-model="content" @input="handleInput" @select="handleSelect" :options="options" :placeholder="placeholder"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        deselectGroupLabel=""
        selectGroupLabel=""
        :loading="loading"
        :custom-label="customlabel"
        :label="label"
        group-values="group_values"
        group-label="group_label"
        :group-select="true">
          <template slot="noOptions">Lista vuota</template>
          <template slot="noResult">Nessun elemento trovato</template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
/*
options example:
      options: [
        {
          group_label: 'Javascript',
          group_values: [
            { name: 'Vue.js', category: 'Front-end' },
            { name: 'Adonis', category: 'Backend' }
          ]
        },
        {
          group_label: 'Ruby',
          group_values: [
            { name: 'Rails', category: 'Backend' },
            { name: 'Sinatra', category: 'Backend' }
          ]
        },
        {
          group_label: 'Other',
          group_values: [
            { name: 'Laravel', category: 'Backend' },
            { name: 'Phoenix', category: 'Backend' }
          ]
        }
      ],

*/
export default {
  name: 'GstMultiselectGroup',
  components: { Multiselect },
  props: {
    value: { type: Object, default: undefined },
    options: { type: Array, default: null },
    placeholder: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    customlabel: { type: Function, default: undefined },
    label: { type: String, default: undefined }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content)
    },
    handleSelect (e) {
      this.$emit('select', e)
    }
  },
  watch: {
    value (value) {
      this.content = value
    }
  },
  data () {
    return {
      content: this.value
    }
  }
}
</script>

<!-- Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import '../../assets/styles/gst.multiselect.style.css';

</style>
