<template>
  <div class="gst-frame">
    <iframe
      @load="loadEventFrame1"
      noresize
      :scrolling="frame1.scrolling"
      :ref="frame1.name"
      :name="frame1.name"
      style="border:none; width:100%;"
      :src="urlFrame1"
    >
      <p>Your browser does not support iframes.</p>
    </iframe>
    <iframe
      v-if="frame2 !== null"
      @load="loadEventFrame2"
      noresize
      :scrolling="frame2.scrolling"
      :ref="frame2.name"
      :name="frame2.name"
      style="border:none; width:100%;"
      :src="urlFrame2"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'GstFrame',
  props: {
    params: Object,
    callback: Function
  },
  data () {
    return {
      frame1: null,
      urlFrame1: '',
      frame2: null,
      urlFrame2: ''
    }
  },
  created () {
    console.log('GstFrame::created') // eslint-disable-line
    this.frame1 = this.params.frames[0]
    if (this.params.frames.length > 1) {
      this.frame2 = this.params.frames[1]
    }
  },
  methods: {
    loadEventFrame1 (evt) {
      console.log('GstFrame::loadEventFrame1', evt) // eslint-disable-line
      if (this.frame1 && this.urlFrame1 !== this.frame1.url) {
        this.urlFrame1 = this.frame1.url
        console.log('GstFrame::loadEventFrame1 set urlFrame1', this.urlFrame1) // eslint-disable-line
      }
      this.resize(this.frame1.name)
    },
    loadEventFrame2 (evt) {
      console.log('GstFrame::loadEventFrame2', evt) // eslint-disable-line
      if (this.frame2 && this.urlFrame2 !== this.frame2.url) {
        this.urlFrame2 = this.frame2.url
        console.log('GstFrame::loadEventFrame2 set urlFrame2', this.urlFrame2) // eslint-disable-line
      }
      this.resize(this.frame2.name)
    },
    resize (ref) {
      console.log('GstFrame::resize', ref) // eslint-disable-line
      let scrollHeight = window.outerHeight * 2
      if (this.params.frames.length > 1) {
        scrollHeight = this.$refs[ref].contentWindow.document.body.scrollHeight
        if (scrollHeight === 0) {
          scrollHeight = window.outerHeight * 2
        }
      }

      this.$refs[ref].height = scrollHeight + 'px'
      if (this.$refs[ref].contentWindow.document.body) {
        this.$refs[ref].contentWindow.document.body.style.margin = 0
        this.$refs[ref].contentWindow.document.body.style.padding = 0
        this.$refs[ref].contentWindow.document.body.style.background = '#faf9f9'
      }
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
@import '../../assets/styles/gst.panel.style.css';
.gst-frame {
  background: #faf9f9;
  z-index: 0;
}
</style>
