<template>
  <div>
    <div ref="gstTabsRef" id="gst-tabs-id" class="gst-tabs">
      <div id="container" class="box-conteiner" :style="containerStyle">
        <div id="main-tabs">
          <ul id="tabs-list">
            <li v-for="(tab, index) in tabs" :key="tab.id" :id="tab.id">
              <a :href="'#'+tab.div">{{ tab.text }}</a>
              <span v-if="tab.isClosable" class="ui-icon ui-icon-close" style="display: inline-block;" @click="closeTab(index)" role="presentation"></span>
            </li>
          </ul>
          <slot></slot>
          <div v-if="fab" class="gst-fab-button">
            <button type="button" id="btnScrollTop" title="Torna in alto" class="ui-button ui-widget ui-corner-all ui-button-icon-only"
              role="button" aria-disabled="false" @click="scrollTop">
              <span class="ui-button-icon-primary ui-icon ui-icon-caret-1-n"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../eventbus'
import { GST_EVENT_CLOSE_TAB, GST_EVENT_CLOSE_CURRENT_TAB, GST_EVENT_CHANGE_CURRENT_TAB } from '../../types'

export default {
  name: 'GstTabs',
  props: {
    tabs: [],
    active: null,
    callback: Function,
    fabScrollTop: { type: Boolean, required: false, default: true }
  },
  data () {
    return {
      handlerTab: null,
      activeIndex: -1,
      lastActiveIndex: -1,
      window: {
        width: 0,
        height: 0
      },
      containerStyle: 'width: 100%',
      fab: false
    }
  },
  created () {
    eventBus.$on(GST_EVENT_CLOSE_CURRENT_TAB, this.closeCurrentTab)
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.onScroll)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    active () {
      this.refreshTabs()
    }
  },
  mounted () {
    var self = this
    this.handlerTab = $('#main-tabs').tabs({ // eslint-disable-line
      activate: function (event, ui) {
        console.log('activate: function (event, ui) - id:', ui.newTab[0].id) // eslint-disable-line     
        if (self.callback) {
          self.callback('activate_tab_id', ui.newTab[0].id)
        }
        $('html,body').scrollTop(0)  // eslint-disable-line
      }
    })
  },
  updated () {
    this.refreshTabs()
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth - 40
      this.window.height = window.innerHeight

      if (document.body.clientWidth < window.innerWidth) {
        this.window.width = document.body.clientWidth - 10
      }
      this.containerStyle = 'width: ' + this.window.width + 'px'
    },
    scrollTop () {
      window.scrollTo(0, 0)
    },
    onScroll () {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || document.documentElement.offsetTop || 0
      if (this.fabScrollTop) {
        this.fab = top > 150
      }
    },
    refreshTabs () {
      this.handlerTab.tabs('refresh')

      this.activeIndex = -1
      if (this.active !== undefined || this.active !== null) {
        this.tabs.forEach((tab, index) => {
          if (tab.id === this.active) {
            this.activeIndex = index
          }
        })
        if (this.activeIndex !== -1) {
          this.handlerTab.tabs('option', 'active', this.activeIndex)
          if (this.lastActiveIndex !== this.activeIndex) {
            this.lastActiveIndex = this.activeIndex
            window.scrollTo(0, 0)
          }
        }
      }
      eventBus.$emit(GST_EVENT_CHANGE_CURRENT_TAB, this.tabs[this.activeIndex])
      console.log('***GstTabs::refreshTabs this.activeIndex', this.activeIndex) // eslint-disable-line     
    },
    closeCurrentTab () {
      if (this.activeIndex !== -1) {
        this.closeTab(this.activeIndex)
      }
    },
    closeTab (index) {
      if (this.callback) {
        this.callback('close_tab_index', index)
      }
      eventBus.$emit(GST_EVENT_CLOSE_TAB, index)
    }
  }
}
</script>

<style scoped>
  .gst-fab-button {
    position: fixed;
    right: 40px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    margin: 0;
    padding: 0;
  }

  #btnScrollTop {
    height: 35px;
    width: 35px;
    border-radius: 19px;
    border: 2px solid #fff;
  }

  #btnScrollTop:hover {
    border: 1px solid #7e7783;
  }

  .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
      background-image: none;
      background-color: #faf9f9;
  }
  .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active a {
      color: black;
      font-weight: bold;
  }
  .ui-widget-header {
      border: 0px solid #aaaaaa;
  }
  .gst-tabs {
      width: fit-content;
      min-width: -moz-available;          /* WebKit-based browsers will ignore this. */
      min-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      min-width: fill-available;
  }
</style>
