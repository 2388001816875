import axios from 'axios'

class SoggettiApi {
  async fetchSoggetti(denominazione, multiente, page, rowsPerPage) {
    console.log('<SoggettiApi.fetchSoggetti> fetchSoggetti: ', denominazione) // eslint-disable-line

    let transazione = 'AS.SOGG.WS.01'

    if (multiente === true) {
      transazione = 'AS.SOGG.WS.04'
    }

    const request = {
      params: {
        tipo_operazione: transazione,
        Denominazione: denominazione,
        paginaCorrente: page,
        nRecPagina: rowsPerPage
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }

    const result = await axios.get('', request, config)
    return result.data
  }

  async fetchSoggettiCF(cf, multiente, page, rowsPerPage) {
    console.log('<SoggettiApi.fetchSoggetti> fetchSoggettiCF: ', cf) // eslint-disable-line

    let transazione = 'AS.SOGG.WS.01'

    if (multiente === true) {
      transazione = 'AS.SOGG.WS.04'
    }

    console.log('<SoggettiApi.fetchSoggetti> fetchSoggettiCF:: transazione: ', transazione, multiente) // eslint-disable-line

    const request = {
      params: {
        tipo_operazione: transazione,
        CodiceFiscale: cf,
        paginaCorrente: page,
        nRecPagina: rowsPerPage
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }

    const result = await axios.get('', request, config)
    return result.data
  }

  async fetchSoggetto(idSoggetto) {
    console.log('<SoggettiApi.fetchSoggetti> fetchSoggetto: ', idSoggetto) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'AS.SOGG.V.01',
        idSoggetto: idSoggetto,
        variante: '99'
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }

    const result = await axios.get('', request, config)
    console.log('<RiversamentiApi.fetchSoggetti> result: ', result) // eslint-disable-line
    console.log('<RiversamentiApi.fetchSoggetti> result.data: ', result.data) // eslint-disable-line
    /* remove comment */
    /*     var data = result.data
    let iStart = result.data.indexOf('<!--')
    let iEnd = result.data.indexOf('-->')
    if (iEnd > iStart) {
      data = result.data.substring(iEnd + 4, result.data.length)
    }
 */
    /* conver data to json */
    return result.data
  }
}

export default new SoggettiApi()
