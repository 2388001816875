import { render, staticRenderFns } from "./GstMainHeader.vue?vue&type=template&id=2588bbea&scoped=true&"
import script from "./GstMainHeader.vue?vue&type=script&lang=js&"
export * from "./GstMainHeader.vue?vue&type=script&lang=js&"
import style0 from "./GstMainHeader.vue?vue&type=style&index=0&id=2588bbea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2588bbea",
  null
  
)

export default component.exports