<template>
  <div class="gst-checkbox">
    <gst-input type="checkbox"
    :label="label"
    :labelbold="labelbold"
    :labelwidth="labelwidth"
    :styletype="styletype"
    :inputheight="inputheight"
    inputwidth="auto"
    @check="changeEvent" :value="value" v-model="value" :disable="disable">
  </div>
</template>

<script>
import GstInput from "./GstInput.vue";

export default {
  name: "GstCheckbox",
  components: {
    GstInput,
  },
  props: {
    label: { type: String, required: false, default: "" },
    value: { type: Boolean, required: false, default: false },
    inputheight: { type: String, required: false, default: "24" },
    labelwidth: { type: String, required: false, default: "auto" },
    labelbold: { type: Boolean, required: false, default: false },
    styletype: { type: String, required: false, default: "inline" },
    disable: { type: Boolean, required: false, default: false },
  },
  styletype: {
    validator: function (value) {
      return ["block", "inline"].indexOf(value) !== -1;
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    changeEvent: function (value) {
      this.$emit("check", value);
    },
  },
};
</script>

<style scoped>
</style>
