<template>
  <div class="gst-form-auto-complete">
    <label v-if="label !==''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <input
      v-model="input"
      type="text"
      :style="inputStyle"
      @keydown="keyDownEvent"
      :id="id"
      :disabled="disable"
    />
  </div>
</template>

<script>
export default {
  name: "GstFormAutoComplete",
  props: {
    field: { type: String, required: true },
    value: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: "" },
    labelwidth: { type: String, required: false, default: "auto" },
    inputwidth: { type: String, required: false, default: "200px" },
    labelbold: { type: Boolean, required: false, default: false },
    styletype: { type: String, required: true },
    delay: { type: Number, required: false, default: 500 },
    minLength: { type: Number, required: false, default: 5 },
    source: { type: Object, required: true },
    disable: { type: Boolean, required: false },
    scroll: { type: Boolean, required: false },
    maxHeight: { type: Number, required: false }
  },
  data() {
    return {
      id: "",
      input: "",
      cache: undefined
    };
  },
  created: function() {
    //document.querySelector(".ui-autocomplete").style.max-height = this.max-heght;
  },
  computed: {
    inputStyle() {
      return "width: " + this.inputwidth + ";" + " height: 24px;";
    },
    labelClass() {
      return this.styletype === "inline"
        ? "gst-input-label-inline"
        : this.styletype === "block"
        ? "gst-input-label-block"
        : "gst-input-label";
    },
    labelStyle() {
      return (
        "width: " +
        this.labelwidth +
        ";" +
        "display: " +
        (this.styletype === "inline" ? "inline-block" : this.styletype) +
        ";" +
        "font-weight: " +
        (this.labelbold ? "bold" : "normal") +
        ";"
      );
    }
  },
  watch: {
    input(value) {
      if (value === "") {
        this.$emit("change", undefined);
      }
    },
    value(value) {
      // console.log('<GstFormAutocomplete::watch> - value:', value) // eslint-disable-line
      this.input =
        value &&
        value !== undefined &&
        this.field &&
        this.field !== undefined &&
        value[this.field] &&
        value[this.field] !== undefined
          ? value[this.field]
          : "";
    }
  },
  methods: {
    select(event, ui) {
      console.log("<GstFormAutocomplete::select> - ui:", ui); // eslint-disable-line
      const item = ui.item.object;
      if (item) {
        this.$emit("change", item);
      } else {
        this.input = undefined;
        this.$emit("change", undefined);
      }
    },
    keyDownEvent: function(event) {
      this.$emit("keydown", event);
    },
    error(request, status, error) {
      console.log("<GstFormAutocomplete::error> - request:", request); // eslint-disable-line
      console.log("<GstFormAutocomplete::error> - status:", status); // eslint-disable-line
      console.log("<GstFormAutocomplete::error> - error:", error); // eslint-disable-line
    }
  },
  created() {
    this.id = this.field + "_" + Date.now();
    console.log("<GstFormAutoComplete::created> - value:", this.value); // eslint-disable-line
    this.input =
      this.value &&
      this.value !== undefined &&
      this.field &&
      this.field !== undefined &&
      this.value[this.field] &&
      this.value[this.field] !== undefined
        ? this.value[this.field]
        : "";
  },
  mounted() {
    console.log("<GstFormAutoComplete::mounted>"); // eslint-disable-line
    const self = this;
    $("#" + this.id).autocomplete({
      // eslint-disable-line
      // source: self.source,
      source: function(request, response) {
        self.source(request, response);
      },
      error: self.error,
      select: self.select,
      minLength: self.minLength,
      delay: self.delay,
      scroll: true
    });
    if (this.scroll === true) {
      const height = this.maxHeight ? this.maxHeight + "px" : "200px";
      $("#" + this.id)
        .autocomplete("widget")
        .attr(
          "style",
          "max-height: " + height + "; overflow-y: auto; overflow-x: hidden;"
        );
    }
  }
};
</script>

<style scoped>
@import "../../assets/styles/gst.input.style.css";
</style>
