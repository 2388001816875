<template>
  <div class="gst-input" :style="componentStyle">
    <label v-if="!labelappend && label !==''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <input
      :class="inputClass" :style="inputStyle" v-mask="inputMask"
      :type="type" :disabled="disable" :name="name"
      @change="changeEvent($event)"
      @keypress="keypressEvent"
      @keydown="keydownEvent"
      @keyup="keyupEvent"
      @blur="blurEvent"
      @focus="focusEvent"
     :placeholder="placeholder" v-model="displayValue">
    <label v-if="labelappend && label !==''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'GstInput',
  directives: { mask: VueMaskDirective },
  props: {
    type: { type: String, required: false, default: 'text' },
    value: { type: String, required: false },
    name: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    labelbold: { type: Boolean, required: false, default: false },
    labelappend: { type: Boolean, required: false, default: false },
    labelwidth: { type: String, required: false, default: 'auto' },
    inputwidth: { type: String, required: false, default: 'auto' },
    inputheight: { type: String, required: false, default: '24' },
    inputalign: { type: String, required: false, default: 'left' },
    styletype: { type: String, required: true },
    placeholder: { type: String, required: false },
    mask: { type: String, required: false },
    size: { type: Number, required: false },
    error: { type: String, required: false },
    disable: { type: Boolean, required: false },
    currency: { type: Boolean, required: false }
  },
  styletype: {
    validator: function (value) {
      return ['block', 'inline'].indexOf(value) !== -1
    }
  },
  data () {
    return {
      inputError: '',
      inputMask: '',
      inputPlaceholder: '',
      inputSize: 0,
      isInputActive: false,
      formatter: null
    }
  },
  created () {
    this.inputMask = this.mask || ''
    this.inputError = this.error || ''
    this.inputSize = this.size || 255
    this.inputPlaceholder = this.placeholder || ''

    switch (this.mask) {
      case 'number':
        this.inputMask = '#'.repeat(this.inputSize)
        break
      case 'date':
        this.inputMask = '##/##/####'
        this.inputPlaceholder = 'gg/mm/aaaa'
        break
    }
    this.formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    })
  },
  computed: {
    componentStyle () {
      return 'display: ' + (this.styletype === 'inline') ? 'inline-block' : (this.styletype === 'block' ? 'block' : '') + ';'
    },
    labelClass () {
      return (this.styletype === 'inline') ? 'gst-input-label-inline' : (this.styletype === 'block' ? 'gst-input-label-block' : 'gst-input-label')
    },
    inputClass () {
      return (this.type === 'checkbox') ? 'gst-input-checkbox' : 'gst-input-text'
    },
    labelStyle () {
      return 'width: ' + this.labelwidth + ';' +
      'display: ' + (this.styletype === 'inline' ? 'inline-block' : this.styletype) + ';' +
      'font-weight: ' + (this.labelbold ? 'bold' : 'normal') + ';'
    },
    inputStyle () {
      return 'width: ' + this.inputwidth + ';' +
      'height: ' + this.inputheight + 'px!important; ' +
      'vertical-align: middle' + ';' +
      'display: ' + (this.styletype === 'inline' ? 'inline-block' : this.styletype) + ';' +
      'text-align: ' + this.inputalign + ';'
    },
    displayValue: {
      get: function () {
        if (!this.currency || this.isInputActive) {
          return this.value
        } else {
          var formatted = this.formatter.format(this.value)
          return formatted
        }
      },
      set: function (modifiedValue) {
        var newValue = modifiedValue
        if (this.currency) {
          var noPointValue = modifiedValue.replace(/\./g, '')
          newValue = parseInt(noPointValue)
          if (isNaN(newValue)) {
            newValue = 0
          }
        }
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    changeEvent (event) {
      this.$emit('check', event.target.checked)
    },
    keypressEvent (event) {
      this.$emit('keypress', event)
    },
    keydownEvent (event) {
      this.changeEvent(event)
      this.$emit('keydown', event)
    },
    keyupEvent (event) {
      this.$emit('keyup', event)
    },
    blurEvent (event) {
      this.isInputActive = false
    },
    focusEvent (event) {
      this.isInputActive = true
    }
  }
}
</script>

<style scoped>
@import '../../assets/styles/gst.input.style.css';
</style>
