<template>
    <div class="gst-main-body" :class="classComponent">
        <splitpanes
          class="default-theme"
          style="height: 1200px"
          @resized="resized($event)"
          @splitter-click="click($event)"
          >
          <transition name="fade" appear>
            <pane
              :min-size="menuMinSize"
              :maxSize="menuMaxSize"
              :size="menuSize" v-show="showMenu">
              <div class="gst-vertical-menu" style="height: 100%">
                <slot name="vertical-nav-bar"></slot>
              <div>
            </pane>
          </transition>

            <pane
              :size="showMenu ? 100 - menuSize: 100"
              >
              <div class="gst-main-content" style="height: 100%">
                <slot name="content"></slot>
              </div>
            </pane>
        </splitpanes>
    </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
  name: 'GstMainBody',
  components: { Splitpanes, Pane },
  props: {
    menuSize: { type: Number, required: false, default: 20 },
    menuMinSize: { type: Number, required: false, default: 20 },
    showMenu: { type: Boolean, required: false, default: true },
    fixed: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      classComponent: '',
      menuMaxSize: 50,
      menuCurrentSize: 0,
      cursorCss: 'col-resize'
    }
  },
  created () {
    if (this.fixed) {
      this.menuMaxSize = this.menuMinSize = this.menuSize
    }
  },
  watch: {
  },
  methods: {
    resized (event) {
      this.menuCurrentSize = event[0].size
      console.log('menuCurrentSize:', this.menuCurrentSize) // eslint-disable-line
    },
    click (event) {
      console.log('click:', event) // eslint-disable-line
    },
    dblclick (event) {
      console.log('dblclick:', event) // eslint-disable-line
    }
  }
}
</script>
<style>
.splitpanes__splitter,
.splitpanes__pane {
    background-color:transparent !important;
}
.default-theme.splitpanes--vertical > .splitpanes__splitter,
.default-theme .splitpanes--vertical > .splitpanes__splitter {
    border-left: 0px !important;
    margin-left: 0px !important;
    border-right: 1px solid #eee;
    margin-right: 1px;
}
</style>
