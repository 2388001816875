import soggettiApi from '../../api/soggetti.api'

const state = {
  soggetti: null
}

const getters = {
  getSoggetti: state => {
    return state.soggetti
  }
}

const actions = {
  fetchSoggetti: (context, params) => {
    console.log('<soggetti.fetchSoggetti> params:', params) // eslint-disable-line  
    soggettiApi.fetchSoggetti(params.denominazione, params.page, params.rowsPerPage)
      .then(data => context.commit('setSoggetti', data))
  }
}

const mutations = {
  setSoggetti: (state, soggetti) => {
    state.soggetti = soggetti
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
