<template>
  <div class="gst-button" :style="componentStyle">
    <button
      type="button"
      :id="id"
      :title="tooltip"
      :hidden="state === 'hidden'"
      :disabled="state === 'disabled'"
      :style="buttonStyle"
      @click.stop="$emit('click')"
      :class="buttonClass"
      role="button" aria-disabled="false">
      <template v-if="type==='material'">
        <span v-if="isIcon " class="material-icons" style="width: 24px;">{{icon}}</span>
        <span v-if="text.length > 0" class="ml-1 mt-1"><b>{{ text }}<b></span>
      </template>
      <template v-else-if="type==='jquery'">
        <span v-if="isIcon " :class="'ui-button-icon-primary ui-icon ui-icon-' + icon"></span>
        <span v-if="text.length > 0" class="ui-button-icon-space"></span>
        <span v-if="text.length > 0" class="ui-button-text">{{ text }}</span>
      </template>
    </button>
  </div>
</template>

<script>
// ui-button-icon-only
// ui-state-default ui-button-text-icon-primary
export default {
  name: 'GstButton',
  props: {
    id: { type: String, required: true, default: '' },
    title: { type: String, required: true, default: '' },
    text: { type: String, required: true, default: '' },
    icon: { type: String, required: false, default: '' },
    tooltip: { type: String, required: false, default: '' },
    state: { type: String, required: true, default: 'enabled' },
    width: { type: String, required: false, default: 'auto' },
    height: { type: String, required: false, default: 'auto' },
    styletype: { type: String, required: false, default: 'inline' },
    type: { type: String, required: false, default: 'jquery' }
  },
  state: {
    validator: function (value) {
      return ['enabled', 'disabled', 'hidden'].indexOf(value) !== -1
    }
  },
  data () {
    return {
      isIcon: false
    }
  },
  created () {
    this.isIcon = !!(this.icon && this.icon.length)

    this.tooltip = this.tooltip.length === 0 ? this.title : this.tooltip

    if (this.text.length === 0) {
      this.width = '22px'
      this.height = '22px'
    }
  },
  computed: {
    componentStyle () {
      var styleData = 'vertical-align: middle; '
      if (this.styletype === 'inline') {
        styleData += 'display: inline-block;'
      } else if (this.styletype === 'block') {
        styleData += 'display: block;'
      }
      return styleData
    },
    buttonStyle () {
      var styleData = 'height: ' + this.height + '; ' + 'width: ' + this.width + '; '
      if (this.type === 'material') {
        styleData += 'padding: 0px; margin: 0px 0px 4px 0px;'
      }
      return styleData
    },
    buttonClass () {
      var classData = ''
      if (this.type === 'material') {
        classData += 'btn d-flex justify-content-center align-content-between'
      } else if (this.type === 'jquery') {
        classData += 'gst-ui-button ui-button ui-widget ui-corner-all '
        if (this.state === 'disabled') {
          classData += 'ui-button-disabled ui-state-disabled '
        }
        if (this.text.length > 0) {
          classData += 'ui-button-text-icon-primary '
        } else {
          classData += 'ui-button-icon-only '
        }
      }
      return classData
    }
  }
}
</script>

<style scoped>
.gst-ui-button {
    margin: 2px;
}
</style>
