<template>
  <div class='gst-form-auto-complete-legacy'>
    <label v-if="label !==''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <input class="gst-input-text" type='text'
     v-model='input'
     :style='inputStyle'
     @input='filterTimer($event)'
     @click="focus(true)"
     @focus='focus(true)'
     @blur='focus(false)'
     @keydown='keyDownEvent($event)' >
    <gst-spinner-input v-if='isLoader' />
    <div v-if='focused' :style="divTableStyle">
      <table :style='tableStyle' ref="tab">
        <tbody>
          <tr v-for='(item, index) in matches' :key='index' @mousedown='itemSelected(item)'>
            <td :class="item.selected ? 'gst-item-selected' : ''">
              <slot name="item-label" v-bind:item="item"> {{ item[field] }} </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import GstSpinnerInput from './GstSpinnerInput'
export default {
  name: 'GstFormAutoCompleteLegacy',
  components: {
    GstSpinnerInput
  },
  props: {
    value: { type: String, required: false, default: undefined },
    items: { type: Array, required: true },
    field: { type: String, required: true },
    fieldId: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    labelwidth: { type: String, required: false, default: 'auto' },
    inputwidth: { type: String, required: false, default: '200px' },
    labelbold: { type: Boolean, required: false, default: false },
    isLoader: { type: Boolean, required: true, default: false },
    styletype: { type: String, required: true },
    delay: { type: Number, required: false, default: 500 },
    filterEnabled: { type: Boolean, required: false, default: false },
    filterDigits: { type: Number, required: false, default: 5 }
  },
  data () {
    return {
      input: '',
      focused: false,
      indexTable: -1,
      filterTimeout: null,
      internalItems: [],
      lastValue: ''
    }
  },
  computed: {
    divTableStyle () {
      return (this.styletype === 'inline') ? 'margin-left: ' + this.labelwidth : '' + ';'
    },
    tableStyle () {
      return 'width: ' + this.inputwidth + ';' + ' margin-left: ' + (this.styletype === 'inline') ? '6px;' : '2px;' + ';'
    },
    inputStyle () {
      return 'width: ' + this.inputwidth + ';' + ' height: 24px;'
    },
    labelClass () {
      return (this.styletype === 'inline') ? 'gst-input-label-inline' : (this.styletype === 'block' ? 'gst-input-label-block' : 'gst-input-label')
    },
    labelStyle () {
      return 'width: ' + this.labelwidth + ';' +
      'display: ' + (this.styletype === 'inline' ? 'inline-block' : this.styletype) + ';' +
      'font-weight: ' + (this.labelbold ? 'bold' : 'normal') + ';'
    },
    // Filtering the suggestion based on the input
    matches () {
      return this.internalItems.filter((elem) => {
        return elem[this.field].toUpperCase().indexOf(this.input.toUpperCase()) >= 0
      })
    }
  },
  watch: {
    input (value) {
      if (value === '') {
        this.$emit('change', undefined)
      }
    },
    value (value) {
      console.log('<GstFormAutoCompleteLegacy::value> watch*** ', value) // eslint-disable-line
      this.input = (value && value !== undefined &&
                    this.field && this.field !== undefined &&
                    value[this.field] && value[this.field] !== undefined) ? value[this.field] : ''
    },
    items: {
      handler (value) {
        if (value) {
          this.buildItems()
        }
      },
      deep: true
    }
  },
  methods: {
    buildItems () {
      if (this.filterTimeout != null) {
        clearTimeout(this.filterTimeout)
      }
      this.indexTable = -1
      if (this.items && this.items.length > 0) {
        this.internalItems = []
        this.items.forEach(elem => {
          this.internalItems.push({
            ...elem,
            selected: false
          })
        })
        this.focused = true
      }
    },

    keyDownEvent (event) {
      if (this.filterTimeout != null) {
        clearTimeout(this.filterTimeout)
      }

      if (this.isLoader) {
        event.preventDefault()
      } else {
        if (this.matches.length > 0) {
          if (event.keyCode === 40) {
            console.log('GstFormAutoCompleteLegacy :: keyDownEvent :: down') // eslint-disable-line
            if (this.indexTable >= this.matches.length - 1) {
              this.indexTable = 0
            } else {
              this.indexTable++
            }
          } else if (event.keyCode === 38) {
            console.log('GstFormAutoCompleteLegacy :: keyDownEvent :: up') // eslint-disable-line
            if (this.indexTable <= 0) {
              this.indexTable = this.matches.length - 1
            } else {
              this.indexTable--
            }
          } else if (event.keyCode === 13) {
            console.log('GstFormAutoCompleteLegacy :: keyDownEvent :: enter') // eslint-disable-line
            if (this.focused === true) {
              this.itemSelected(this.matches[this.indexTable])
            }
          } else if (event.keyCode === 27) {
            console.log('GstFormAutoCompleteLegacy :: keyDownEvent :: esc') // eslint-disable-line
            if (this.indexTable === -1) {
              this.focused = false
            }
            this.indexTable = -1
          }

          this.matches.forEach(elem => {
            elem.selected = false
          })

          if (this.indexTable !== -1) {
            this.matches[this.indexTable].selected = true
          }
        }
        return true
      }
    },
    itemSelected (item) {
      this.focused = false
      this.indexTable = -1
      this.select(item)
    },
    filterTimer (event) {
      if ((event.target.value.length >= this.filterDigits) && this.filterEnabled) {
        if (this.lastValue !== event.target.value.toUpperCase()) {
          this.filterTimeout = setTimeout(() => {
            this.lastValue = event.target.value.toUpperCase()
            this.$emit('input', event.target.value)
            this.internalItems = []
          }, this.delay)
        }
      }
    },
    select (item) {
      console.log('GstFormAutoCompleteLegacy::select - item:', item) // eslint-disable-line
      if (item) {
        item.selected = true
        this.input = item[this.field]
        this.$emit('change', item)
      } else {
        this.input = undefined
        this.$emit('change', undefined)
      }
    },
    focus: function (flag) {
      console.log('GstFormAutoCompleteLegacy::focus - flag:', flag, 'field:', this.field) // eslint-disable-line
      this.focused = flag
      this.indexTable = -1
    },
    isPreset: function (list, item) {
      var result = list.filter((elem) => {
        return elem[this.field].toUpperCase().indexOf(item[this.field].toUpperCase()) >= 0
      })
      return (result && result.length > 0)
    }
  },
  created () {
    console.log('<GstFormAutoCompleteLegacy::Created>') // eslint-disable-line
    console.log('<GstFormAutoCompleteLegacy::Created> - value:', this.value) // eslint-disable-line

    this.input = (this.value && this.value !== undefined &&
                  this.field && this.field !== undefined &&
                  this.value[this.field] && this.value[this.field] !== undefined) ? this.value[this.field] : ''
  }
}
</script>

<style scoped>
@import '../../assets/styles/gst.input.style.css';

.gst-form-auto-complete table {
  position: absolute;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  border: 1px;
  border-top: 0px;
  cursor: pointer;
  z-index: 4;
}

.gst-form-auto-complete table tr {
  background: white;
}

.gst-form-auto-complete table tr td {
  padding: 5px;
  border: 1px solid #e2e2e8;
}

.gst-form-auto-complete table tr:nth-child(even) {
  background: #eee;
}

.gst-form-auto-complete table tr:hover {
  background: #a6c0d4;
  color: white;
}

.gst-item-selected {
  background: #a6c0d4;
  color: white;
}

</style>
