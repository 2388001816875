import axios from 'axios'

let OAUTH_CLIENT_ID = "6a77432a-0ed7-4609-8665-8faaec44169b";
let OAUTH_CLIENT_SECRET = "c23ecb32-054d-40ab-9126-f219e6e1f97a";
let baseUrl = window.localStorage.getItem("apiEndpoint");
class OAuthApi {
  fetchRefreshToken(refreshoken) {
    console.log(
      '<OAuthApi.fetchRefreshToken> refreshoken: ',
      refreshoken
    )
    let url = baseUrl + '/oauth/token'
    let request = 'refresh_token=' + refreshoken + '&grant_type=refresh_token'
    let authorizationBasic = Buffer.from(OAUTH_CLIENT_ID + ':' + OAUTH_CLIENT_SECRET).toString('base64')
    let config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + authorizationBasic,
        'cache-control': 'no-cache',
      },
      responseType: 'json',
    }
    return axios.post(url, request, config)
  }

  async fetchTokenUsingPassword(username, password) {
    console.log('<OAuthApi.fetchTokenUsingPassword> username: ' + username)
    const url = '/oauth/token'
    const request =
      'username=' + username + '&password=' + password + '&grant_type=password'

    const authorizationBasic = Buffer.from(OAUTH_CLIENT_ID + ':' + OAUTH_CLIENT_SECRET).toString('base64')
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + authorizationBasic,
        'cache-control': 'no-cache',
      },
      responseType: 'json',
    }
    return axios.post(url, request, config)
  }
}

export default new OAuthApi()
