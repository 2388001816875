import axios from 'axios'

class ComuniApi {
  async fetchComuni (comune, page, rowsPerPage) {
    console.log('<ComuniApi.fetchComuni> comune: ', comune) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'SC.T15.R.01',
        initComune: comune,
        paginaCorrente: page,
        nRecPagina: rowsPerPage
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    console.log('<ComuniApi.fetchComuni> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)
    return result.data
  }
}

export default new ComuniApi()
