<template>
  <div class="gst-accordion" :class="accordionClasses">
    <button class="gst-accordion-header" @click="toggleAccordion" :class="accordionClasses">
      <span :class="accordionIcon"></span>
      {{title}}
    </button>
    <div class="gst-accordion-body">
      <div class="gst-accordion-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GstAccordion',
  props: {
    show: { type: Boolean, required: false, default: false },
    title: { type: String, required: true }
  },
  data: function () {
    return {
      isOpen: false
    }
  },
  created () {
    this.isOpen = !!(this.show)
  },
  computed: {
    accordionClasses: function () {
      return {
        'is-closed': !this.isOpen
      }
    },
    accordionIcon: function () {
      return (this.isOpen) ? 'ui-icon ui-icon-triangle-1-s' : 'ui-icon ui-icon-triangle-1-e'
    }
  },
  methods: {
    toggleAccordion: function () {
      this.isOpen = !this.isOpen
      this.$emit('change', this.isOpen)
    }
  }
}

</script>

<style scoped>

.gst-accordion-header {
  background-color: #eee;
  color: #222222;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  height: 32px;
  text-align: left;
  background: #cfcfdc;

  border: 1px solid #aaaaaa;
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
  border-radius: 5px 5px 0px 0px;
}

.is-closed .gst-accordion-header {
  border-radius: 5px 5px 5px 5px;
}

.gst-accordion-body   {
  padding: 0;
  max-height: unset;
  overflow: hidden;
  transition: 2.0s ease all;
}

.is-closed .gst-accordion-body {
  transition: 0.5s;
  max-height: 0;
}

.gst-accordion-content {
  padding: 10px;
  background: #e2e2e8;
  border: 1px solid #aaaaaa;
  border-top: 0px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 0px 0px 5px 5px;
}

</style>
