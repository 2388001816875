<template>
  <b-spinner small class="loaderPosition" variant="info"></b-spinner>
</template>

<script>
export default {
  name: 'GstSpinnerInput'
}
</script>

<style scoped>
.loaderPosition {
  margin-top: 10px;
  margin-left: -16px;
  margin-right: 0px;
  position: absolute;
}
</style>
