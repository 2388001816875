<template>
  <div class="gst-combobox">
    <label v-if="label !== ''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <select
      :id="id"
      v-if="items.length == 1"
      class="gst-input-text"
      :disabled="disable"
      :style="inputStyle"
    >
      <option selected>{{ singleItem }}</option>
    </select>
    <select
      v-else
      class="gst-input-text"
      :disabled="disable"
      :id="id"
      @change="itemSelected($event)"
      v-model="selected"
      :style="inputStyle"
      :size="size"
      :multiple="multiple"
    >
      <option
        v-for="item in items"
        :key="item.id"
        :disabled="item.disabled ? item.disabled : false"
      >
        {{ item[field] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "GstCombobox",
  props: {
    value: { type: Object, required: false },
    items: { type: Array, required: true },
    field: { type: String, required: true },
    label: { type: String, required: false, default: "" },
    styletype: { type: String, required: false, default: "inline" },
    labelbold: { type: Boolean, required: false, default: false },
    labelwidth: { type: String, required: false, default: "auto" },
    inputwidth: { type: String, required: false, default: "auto" },
    disable: { type: Boolean, required: false },
    size: { type: String, required: false },
    multiple: { type: Boolean, required: false },
    id: { type: String, required: false },
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    itemSelected(event) {
      console.log("<GstCombobox::itemSelected> event.target.value:", event); // eslint-disable-line
      if (this.multiple) {
        var objects = [];
        this.selected.forEach((value) => {
          this.items.forEach((item) => {
            if (item[this.field] === value) {
              objects.push(item);
            }
          });
        });
        this.$emit("changeList", objects, event);
      }

      this.items.forEach((item, index) => {
        if (item[this.field] === event.target.value) {
          this.$emit("change", item);
        }
      });
    },
    setInput(value) {
      console.log("<GstCombobox::setInput> newValue:", value); // eslint-disable-line
      this.selected =
        value && value !== undefined && value !== null ? value[this.field] : "";
    },
  },
  computed: {
    labelClass() {
      return this.styletype === "inline"
        ? "gst-input-label-inline"
        : "gst-input-label-block";
    },
    labelStyle() {
      return (
        "width: " +
        this.labelwidth +
        ";" +
        "display: " +
        (this.styletype === "inline" ? "inline-block" : this.styletype) +
        ";" +
        "font-weight: " +
        (this.labelbold ? "bold" : "normal") +
        ";"
      );
    },
    inputStyle() {
      return (
        "width: " +
        this.inputwidth +
        ";" +
        (this.size ? "" : "height: 24px;") +
        "display: " +
        (this.styletype === "inline" ? "inline-block" : this.styletype) +
        ";" +
        "text-align: left;"
      );
    },
    singleItem() {
      if (this.value) {
        return this.value[this.field];
      } else {
        var iterator = this.items.values();
        var value = iterator.next().value[this.field];
        return value || "";
      }
    },
  },
  watch: {
    value(value) {
      if (value) {
        console.log("<GstCombobox::watch> this.value:", value); // eslint-disable-line
        this.setInput(value);
      }
    },
  },
  created() {
    console.log("<GstCombobox::created> this.size:", this.size); // eslint-disable-line
    this.setInput(this.value);
  },
};
</script>

<style scoped>
@import "../../assets/styles/gst.input.style.css";
</style>
