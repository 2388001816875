var convert = require('xml-to-json-promise')

class ResponseWSHelper {
  async parse (responseWS) {
    const json = await convert.xmlDataToJSON(responseWS)
    this.codiceMessaggio = json.ResponseWS.MessageWS[0].$.codiceMessaggio
    console.log('<ResponseWSHelper> codiceMessaggio: ', this.codiceMessaggio) // eslint-disable-line  
    this.result = (json.ResponseWS.Result !== undefined) ? json.ResponseWS.Result[0] : ''
  }

  getCodiceMessaggio () {
    return this.codiceMessaggio
  }

  getResult () {
    return this.result
  }
}
export default new ResponseWSHelper()
