import stradarioApi from './stradario.api'
import soggettiApi from './soggetti.api'
import comuniApi from './comuni.api'
import riversamentiApi from './riversamenti.api'
import oggettiCatastaliApi from './oggetti.catastali.api'
import interventiApi from './interventi.api'
import helperApi from './helper.api'
import http from './http.client'

export {
  stradarioApi,
  soggettiApi,
  comuniApi,
  riversamentiApi,
  oggettiCatastaliApi,
  interventiApi,
  helperApi,
  http
}
