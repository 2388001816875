<template>
    <div class="gst-col col" :class="colClass">
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'GstCol',
  props: {
    breakpoint: { type: String, required: false, default: 'auto' },
    break: { type: Boolean, required: false, default: false },
    align: { type: String, required: false, default: '' },
    order: { type: String, required: false, default: '' },
    center: { type: Boolean, required: false, default: false },
    right: { type: Boolean, required: false, default: false },
    left: { type: Boolean, required: false, default: true }
  },
  computed: {
    colClass () {
      if (this.break) {
        return 'w-100'
      }
      return (this.breakpoint === '4') ? 'col-12 col-md-4 col-lg-3 col-xl-3'
        : (this.breakpoint === '3') ? 'col-12 col-md-6 col-lg-4 col-xl-4 '
          : (this.breakpoint === '2') ? 'col-12 col-md-6 col-lg-6 col-xl-6 '
            : (this.breakpoint === '1') ? 'col-12 col-md-12 col-lg-12 col-xl-12 '
              : (this.breakpoint === 'auto') ? 'col-auto col-md-auto col-lg-auto col-xl-auto ' : '' +
            (this.align === 'start') ? 'align-self-start '
                : (this.align === 'center') ? 'align-self-center '
                  : (this.align === 'end') ? 'align-self-end ' : '' +
            (this.order !== '') ? 'order-' + this.order + ' ' : '' +
            (this.center) ? 'text-align: center; ' : (this.right) ? 'text-align: right; ' : 'text-align: left; '
    }
  }
}
</script>
