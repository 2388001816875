import axios from 'axios'
import stradaModel from '../models/strada.model'

class StradaApi {
  async fetchStradaLegacy (value) {
    console.log('<StradaApi.fetchStrada> value: ', value) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'AF.AUTST.WS.01',
        initStrada: value.toUpperCase(),
        paginaCorrente: '1',
        nRecPagina: '20'
      }
    }

    const result = await axios.get('', request)
    console.log('StradaApi::fetchStradaLegacy result', result) // eslint-disable-line
    return stradaModel.fromXmlToJson(result.data)
  }

  async fetchStrade (nome, page, rowsPerPage) {
    console.log('<StradaApi.fetchStrada> nome: ', nome) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'AF.AUTST.WS.01',
        initStrada: nome.toUpperCase(),
        paginaCorrente: page,
        nRecPagina: rowsPerPage
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    const result = await axios.get('', request, config)
    return result.data
  }
}

export default new StradaApi()
