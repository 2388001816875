import axios from 'axios'

class HelperApi {
  async cambioEnte (codiceEnte) {
    console.log('<Helper.cambioEnte> nuovo ente ', codiceEnte) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'MB.MEN.M.01',
        xmlOutPut: 'S',
        nuovoEnte: codiceEnte
      }
    }

    const config = {}

    console.log('<Helper.cambioEnte> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)
    return result.data
  }

  async saveNotificationToken (token) {
    console.log('<Helper.saveNotificationToken> token ', token) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'MB.FCM.A.01',
        fcm_token: token
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }

    console.log('<Helper.saveNotificationToken> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)
    return result.data
  }
}

export default new HelperApi()
