<template>
    <div class="gst-row row" :class="rowClass">
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'GstRow',
  props: {
    align: { type: String, required: false, default: '' },
    justify: { type: String, required: false, default: '' },
    nomargin: { type: Boolean, required: false, default: true }
  },
  computed: {
    rowClass () {
      var classData = ''
      if (this.align !== '') {
        classData += (this.align === 'start') ? 'align-items-start '
          : (this.align === 'center') ? 'align-items-center '
            : (this.align === 'end') ? 'align-items-end ' : ''
      }
      if (this.justify !== '') {
        classData += (this.justify === 'start') ? 'justify-content-start '
          : (this.justify === 'center') ? 'justify-content-center '
            : (this.justify === 'end') ? 'justify-content-end '
              : (this.justify === 'around') ? 'justify-content-around '
                : (this.justify === 'between') ? 'justify-content-between '
                  : ''
      }
      if (this.nomargin) {
        classData += 'no-gutters'
      }
      return classData
    }
  }
}
</script>
<style scoped>
.row {
    margin: 2px 0 2px 0;
}
</style>
