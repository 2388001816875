<template>
    <div class="gst-multiselect">
      <multiselect
        v-bind="$attrs"
        v-on="$listeners"
        v-model="content"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        deselectGroupLabel=""
        selectGroupLabel=""
        >
          <template slot="noOptions">Lista vuota</template>
          <template slot="noResult">Nessun elemento trovato</template>
        </multiselect>
    </div>
</template>

<script>
// https://vue-multiselect.js.org/
import Multiselect from 'vue-multiselect'

export default {
  name: 'GstMultiselect',
  components: { Multiselect },
  props: {
    value: { type: Object, default: undefined }
  },
  watch: {
    value (value) {
      this.content = value
    }
  },
  data () {
    return {
      content: this.value
    }
  }
}
</script>

<!-- Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import '../../assets/styles/gst.multiselect.style.css';
</style>
