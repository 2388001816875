<template>
    <div class="gst-multiselect-async">
      <multiselect v-model="content" @input="handleInput" @select="handleSelect" @close="handleClose" :options="options" :placeholder="placeholder"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        deselectGroupLabel=""
        selectGroupLabel=""
        :loading="loading"
        :custom-label="customlabel"
        :label="label"
        @search-change="asyncFindInternal"
        :searchable="true"
        :show-no-results="false"
        :show-no-options="false"
        :internal-search="false">
          <template slot="noOptions">Lista vuota</template>
          <template slot="noResult">Nessun elemento trovato</template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'GstMultiselectAsync',
  components: { Multiselect },
  props: {
    value: { type: Object, default: undefined },
    options: { type: Array, default: null },
    placeholder: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    customlabel: { type: Function, default: undefined },
    label: { type: String, default: undefined },
    asyncfind: { type: Function, default: undefined }
  },
  methods: {
    handleClose (e) {
      this.$emit('close', e)
    },
    handleInput (e) {
      this.$emit('input', this.content)
    },
    handleSelect (e) {
      this.$emit('select', e)
    },
    asyncFindInternal (query) {
      this.asyncfind(query)
    }
  },
  watch: {
    value (value) {
      this.content = value
    }
  },
  data () {
    return {
      content: this.value
    }
  }
}
</script>

<!-- Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import '../../assets/styles/gst.multiselect.style.css';

</style>
