<template>
  <div class="gst-datepicker">
    <label v-if="label !== ''" :class="labelClass" :style="labelStyle">
      <span v-html="label" />
    </label>
    <input
      class="gst-input-datepicker"
      type="text"
      :id="datepickerId"
      :style="inputStyle"
      :placeholder="placeholder"
      v-model="value"
      :disabled="disable"
      @change="changeEvent($event)"
    />
    <template v-if="isButton">
      <button
        type="button"
        id="btnCalendar"
        class="ui-button ui-widget ui-corner-all ui-button-icon-only gst-ui-button"
        style="width: 24px; height: 24px; margin-bottom: 1px"
        role="button"
        aria-disabled="false"
        @click="btnCalendar()"
      >
        <span class="ui-button-icon-primary ui-icon ui-icon-calendar"></span>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: "GstDatepicker",
  props: {
    id: { type: String, required: false },
    value: { type: String, required: true },
    label: { type: String, required: false, default: "" },
    image: { type: String, required: false },
    labelwidth: { type: String, required: false, default: "auto" },
    inputwidth: { type: String, required: false, default: "auto" },
    labelbold: { type: Boolean, required: false, default: false },
    dateformat: { type: String, required: false, default: "dd/mm/yy" },
    styletype: { type: String, required: true },
    styletypeInput: { type: String, required: true },
    placeholder: { type: String, required: false },
    disable: { type: Boolean, required: false },
  },
  styletype: {
    validator: function (value) {
      return ["block", "inline"].indexOf(value) !== -1;
    },
  },
  data() {
    return {
      isButton: true,
      datepickerStatus: "hide",
      datepickerId: "datepicker",
    };
  },
  mounted() {
    console.log("<GstDatepicker> dateformat:", this.dateformat); // eslint-disable-line
    var self = this;
    $("#" + this.datepickerId).datepicker({
      // eslint-disable-line
      dateFormat: self.dateformat,
      duration: "slow",
      firstDay: 1,
      autoclose: true,
      showOn: self.isButton ? "off" : "button",
      buttonImage: self.isButton ? "off" : self.image,
      buttonImageOnly: self.isButton ? "off" : true,
      closeText: "Chiudi",
      prevText: "Prec",
      nextText: "Succ",
      currentText: "Oggi",
      monthNames: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
      monthNamesShort: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      dayNames: [
        "Domenica",
        "Lunedi'",
        "Martedi'",
        "Mercoledi'",
        "Giovedi'",
        "Venerdi'",
        "Sabato",
      ],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gio", "Ve", "Sa"],
      onSelect(data) {
        self.datepickerStatus = "hide";
        self.value = data;
        self.$emit("input", data);
      },
      onClose(data) {
        self.datepickerStatus = "hide";
      },
    });
    $.datepicker.setDefaults($.datepicker.regional["it"]); // eslint-disable-line
  },
  updated() {
    if (this.datepickerStatus === "show") {
      $("#" + this.datepickerId).datepicker("show");
    } // eslint-disable-line
    else {
      $("#" + this.datepickerId).datepicker("hide");
    } // eslint-disable-line
  },
  created() {
    if (this.image) {
      this.isButton = !(this.image.length > 0);
    }
    if (this.id) {
      this.datepickerId = this.datepickerId + "-" + this.id;
    }
    if (this.value) {
      $("#" + this.datepickerId).datepicker("setDate", this.value);
    } // eslint-disable-line
  },
  computed: {
    labelClass() {
      return this.styletype === "inline"
        ? "gst-input-label-inline"
        : "gst-input-label-block";
    },
    labelStyle() {
      return (
        "width: " +
        this.labelwidth +
        ";" +
        "display: " +
        (this.styletype === "inline" ? "inline-block" : this.styletype) +
        ";" +
        "font-weight: " +
        (this.labelbold ? "bold" : "normal") +
        ";"
      );
    },
    inputStyle() {
      return "width: " +
        this.inputwidth +
        ";" +
        "height: 24px; vertical-align: middle" +
        ";" +
        "display: " +
        this.styletypeInput
        ? this.styletypeInput === "inline"
          ? "inline-block"
          : this.styletypeInput
        : (this.styletype === "inline" ? "inline-block" : this.styletype) +
            ";" +
            "text-align: center;";
    },
  },
  methods: {
    btnCalendar() {
      this.datepickerStatus =
        this.datepickerStatus === "hide" ? "show" : "hide";
      this.$forceUpdate();
    },
    changeEvent(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
<style scoped>
</style>
