<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      size="sm"
      :hide-header="!showHeader"
      :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <template #modal-header>
          <div class="d-flex justify-content-center gst-loader-header">
            {{title}}
          </div>
        </template>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <b-spinner variant="info"></b-spinner>
            <div style="margin-left:15px; margin-top:5px;">
              <slot></slot>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'GstLoader',
  props: {
    show: { type: Boolean, required: true },
    showHeader: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: '' }
  },
  data () {
    return {
      modalShow: false,
      timeout: null
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        this.timeout = setTimeout(() => {
          this.modalShow = true
          this.timeout = null
        }, 500)
      } else {
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
        this.modalShow = false
      }
    }
  },
  created () {
    this.show = this.show || false
    this.modalShow = this.show
  }
}
</script>
<style>
.gst-loader-header {
  width: 100%;
  background: #336699;
}
.modal-header {
  padding: 0px !important;
  color: white;
  font-weight: bolder;
}
</style>
