import Vue from 'vue'
import { BootstrapVue, IconsPlugin, ModalPlugin, SpinnerPlugin, ButtonPlugin, AvatarPlugin, BadgePlugin, VBHoverPlugin, TooltipPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '../assets/styles/gst.bootstrap.css'

import 'material-design-icons/iconfont/material-icons.css'

// bootstrap vue
Vue.use(BootstrapVue)
// bootstrap components
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ButtonPlugin)
Vue.use(AvatarPlugin)
Vue.use(BadgePlugin)
Vue.use(VBHoverPlugin)
Vue.use(TooltipPlugin)

//
export const VERSION = '0.0.3'
