<template>
  <div :class="styleClass" :style="stylePanel">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GstForm',
  props: {
    styletype: { type: String, required: true, default: 'inline' },
    border: {
      enabled: { type: Boolean, required: true },
      margin: { type: String, required: false },
      padding: { type: String, required: false }
    }
  },
  styletype: {
    validator: function (value) {
      return ['block', 'inline'].indexOf(value) !== -1
    }
  },
  data () {
    return {
      styleClass: '',
      stylePanel: ''
    }
  },
  created () {
    this.styleClass = 'gst-form-' + this.styletype
    if (this.border !== undefined) {
      this.border.margin = this.border.margin || '10px'
      this.border.padding = this.border.padding || '10px'

      if (this.border.enabled === true) {
        this.styleClass = this.styleClass + ' gst-form-panel'
      }
      this.stylePanel = 'padding: ' + this.border.padding + '; margin: ' + this.border.margin + ';'
    }
  }
}
</script>

<style scoped>

.gst-form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 2px;
}

.gst-form-block {
  display: block;
  flex-flow: row wrap;
  align-items: center;
  margin: 2px;
}

.gst-form-panel {
  border-radius: 5px;
  border: 1px solid #336699;
  background: #cfcfdc;
}

</style>
