import axios from 'axios'

class RiversamentiApi {
  async fetchRiversamenti (dataInizio, dataFine) {
    console.log('<RiversamentiApi.fetchRiversamenti> ') // eslint-disable-line
    console.log('<RiversamentiApi.fetchRiversamenti> dataInizio', dataInizio) // eslint-disable-line
    console.log('<RiversamentiApi.fetchRiversamenti> dataFine', dataFine) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'RV.RIV.R.01',
        diniziale: dataInizio,
        dfinale: dataFine,
        variante: '1'
      }
    }

    const config = {
      headers: {
        'Accept': 'application/json', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    console.log('<RiversamentiApi.fetchRiversamenti> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)

    /* remove comment */
    var data = result.data
    const iStart = result.data.indexOf('<!--')
    const iEnd = result.data.indexOf('-->')
    if (iEnd > iStart) {
      data = result.data.substring(iEnd + 4, result.data.length)
    }

    /* conver data to json */
    return JSON.parse(data)
  }
}

export default new RiversamentiApi()
