<template>
  <div class="gst-panel">
    <div class="gst-panel-box-title">
      <slot name="title"></slot>
    </div>
    <div class="gst-panel-box-body">
      <slot name="body"></slot>
    </div>
    <div class="gst-panel-box-toolbar">
        <slot name="toolbar"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GstPanel'
}
</script>

<style scoped>
@import '../../assets/styles/gst.panel.style.css';
</style>
