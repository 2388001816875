import axios from 'axios'

import { BackEnd } from '../../models/backend.model'

const state = {
  backend: BackEnd
}

const getters = {
  // --------------------------------------------------------------------------
  getBaseUrl: state => {
    const baseUrl = window.localStorage.getItem('settings_base_url')
    if (baseUrl != null) {
      state.backend.baseUrl = baseUrl
    }
    console.log('<settings.getBaseUrl> baseUrl: ' + state.backend.baseUrl) // eslint-disable-line 
    return state.backend.baseUrl
  },
  // --------------------------------------------------------------------------
  getBearerToken: state => {
    const bearerToken = window.localStorage.getItem('settings_bearer_token')
    if (bearerToken != null) {
      state.backend.bearerToken = bearerToken
    }
    console.log('<settings.getBaseUrl> bearerToken: ' + state.backend.bearerToken) // eslint-disable-line 
    return state.backend.bearerToken
  }
}

const actions = {
}

const mutations = {
  // --------------------------------------------------------------------------
  setBaseUrl: (state, baseUrl) => {
    console.log('<settings.setBaseUrl> baseUrl: ' + baseUrl) // eslint-disable-line 
    state.backend.baseUrl = baseUrl
    window.localStorage.setItem('settings_base_url', baseUrl)
    axios.defaults.baseURL = state.backend.baseUrl
  },
  // --------------------------------------------------------------------------
  setBearerToken: (state, bearerToken) => {
    console.log('<settings.setBearerToken> bearerToken: ' + bearerToken) // eslint-disable-line 
    state.backend.bearerToken = bearerToken
    window.localStorage.setItem('settings_bearer_token', bearerToken)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
