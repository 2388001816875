<template>
  <div :class="headerClass">
    <b-navbar type="dark" variant="primary">

      <b-navbar-nav class="ml-1">
        <b-nav-item class="gst-main-header__nav_item" id="nav-item-menu">
          <b-icon class="gst-main-header__menu_icon" icon="list" scale="2" variant="white" @click="menuActionEvent"></b-icon>
        </b-nav-item>
        <b-tooltip target="nav-item-menu" :title="isMenuOpen?'Chiudi Menu':'Espandi Menu'" triggers="hover focus" placement="bottomright"></b-tooltip>
      </b-navbar-nav>

      <b-navbar-brand>
        <img :src="image" class="d-inline-block align-top">
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav-form class="gst-main-header__nav_form">
          <div class="gst-main-header__gst-multiselect_package_search">
            <gst-multiselect-async></gst-multiselect-async>
          </div>
        </b-nav-form>
        <b-nav-form class="gst-main-header__nav_form">
          <div class="gst-main-header__gst-multiselect_enti_multiselect">
            <gst-multiselect v-model="modelEnte" :loading="loadingEnti" :options="entiList" label="label" placeholder="Seleziona Ente"> </gst-multiselect>
          </div>
        </b-nav-form>
        <b-nav-item class="gst-main-header__nav_item" id="nav-item-gear-fill">
          <b-icon class="gst-main-header__solo_icon" icon="gear-fill" scale="1.3" variant="white" @click="batchWorkEvent"></b-icon>
        </b-nav-item>
        <b-tooltip target="nav-item-gear-fill" title="Lavori Batch" triggers="hover focus" placement="bottomleft"></b-tooltip>
        <b-nav-item class="gst-main-header__nav_item" id="nav-item-bell">
          <b-nav-item-dropdown toggle-class="text-decoration-none" no-caret right>
            <template v-slot:button-content>
              <div class="gst-main-header__dropdown_button_content">
                <b-icon class="gst-main-header__dropdown_icon" icon="bell" scale="1.3" variant="white"> </b-icon>
                <b-badge v-if="areNotifications" class="gst-main-header__dropdown_icon_badge_notification" pill> {{notifications.length}} </b-badge>
              <div>
            </template>
            <template v-if="areNotifications">
              <b-dropdown-item href="#" key="notification-item-delete" @click="deleteAllNotification"> <b> Elimina Tutto </b> </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item href="#" key="notification-item-no-data"> Nessuna Notifica </b-dropdown-item>
            </template>
            <template v-for="(notification, index) in notifications" >
              <b-dropdown-divider :key="'notification-divider-item-'+ index"></b-dropdown-divider>
              <div
                class="gst-main-header__dropdown_notification-item"
                :key="'notification-item-'+ index"
                v-b-hover="function(hovered){ handleHover(hovered, notification) }"
                @click="notificationMenuEvent(notification)"
              >
                <b-dropdown-header>
                  <div class="gst-main-header__dropdown_notification-item-title" v-html="notification.title" />
                  <div class="gst-main-header__dropdown_notification-item-title-action">
                    <b-icon v-show="notification.show_delete" icon="trash" @click.stop="deleteNotification(index)"></b-icon>
                  </div>
                </b-dropdown-header>
                <b-dropdown-item class="gst-main-header__dropdown_notification-item-content" href="#" v-html="notification.body" />
              </div>
            </template>
          </b-nav-item-dropdown>
        </b-nav-item>
        <b-tooltip target="nav-item-bell" title="Notifiche" triggers="hover focus" placement="bottomleft"></b-tooltip>
        <b-nav-item class="gst-main-header__nav_item_no_gutters" id="nav-item-user">
          <b-nav-item-dropdown toggle-class="text-decoration-none" no-caret right>
            <template v-slot:button-content>
              <div class="gst-main-header__dropdown_button_content">
                <b-avatar class="gst-main-header__dropdown_avatar" :text="enteIniziali"></b-avatar>
              </div>
            </template>
            <b-dropdown-item key="menu-user-item-username"> <b> {{ userName }} </b> </b-dropdown-item>
            <template v-for="(elem, index) in userDropdown" >
              <b-dropdown-item href="#" :key="'menu-user-item-'+ index"  @click="userMenuEvent(elem)" > {{ elem.label }} </b-dropdown-item>
            </template>
          </b-nav-item-dropdown>
        </b-nav-item>
        <b-tooltip target="nav-item-user" :title="'Menu Profilo ' + userName" triggers="hover focus" placement="bottomleft"></b-tooltip>
      </b-navbar-nav>

    </b-navbar>
  </div>
</template>

<script>
import { GST_EVENT_UPDATE_ENTE, GST_EVENT_PUSH_MESSAGE } from '../../types'
import GstMultiselectAsync from './GstMultiselectAsync.vue'
import GstMultiselect from './GstMultiselect.vue'

export default {
  name: 'GstMainHeader',
  components: {
    GstMultiselectAsync,
    GstMultiselect
  },
  props: {
    image: { type: String, required: false, default: '' },
    sticky: { type: Boolean, required: false, default: false },
    userDropdown: { type: Object, required: false, default: undefined },
    globalEventBus: { type: Object, required: true }
  },
  data () {
    return {
      isMenuOpen: true,
      showDelete: false,
      notifications: [],
      modelEnte: null,
      loadingEnti: true,
      entiList: [],
      ente: '',
      enteIniziali: ''
    }
  },
  created () {
    console.log('GstMainHeader::created:') // eslint-disable-line
    try {
      window.gestelVue.firebaseHelper.init(this.globalEventBus)
    } catch (err) {
      console.log('err:', err) // eslint-disable-line
    }

    this.globalEventBus.$on(GST_EVENT_UPDATE_ENTE, this.eventUpdateEnte)
    this.globalEventBus.$on(GST_EVENT_PUSH_MESSAGE, this.eventPushMessage)
    this.emitAction('show-menu', 'click', this.isMenuOpen)
    this.loadEnti()
  },
  computed: {
    headerClass () {
      return this.sticky ? 'gst-main-header-sticky' : 'gst-main-header'
    },
    areNotifications () {
      return this.notifications && this.notifications.length > 0
    }
  },
  watch: {
    modelEnte (newVal, oldVal) {
      if (newVal && oldVal && newVal.value !== oldVal.value) {
        this.changeEnte(newVal.value)
      }
    }
  },
  methods: {
    menuActionEvent () {
      this.isMenuOpen = !this.isMenuOpen
      this.emitAction('menu', 'click', { show: this.isMenuOpen })
    },
    batchWorkEvent () {
      this.emitAction('batch-work', 'click', '')
    },
    userMenuEvent (item) {
      this.emitAction('user-menu', 'click', item)
    },
    eventUpdateEnte (codiceEnte) {
      console.log('GstMainHeader::eventUpdateEnte', codiceEnte) // eslint-disable-line
      console.log('GstMainHeader::eventUpdateEnte', this.modelEnte.value) // eslint-disable-line

      if (this.modelEnte.value === codiceEnte) {
        this.modelEnte = this.entiList.find(elem => {
          return elem.value === codiceEnte
        })
      }
    },
    eventPushMessage: function (pushData) {
      console.log('GstMainHeader::eventPushMessage:', pushData) // eslint-disable-line
      this.pushNotification(pushData.notification)
    },
    notificationMenuEvent (item) {
      console.log('GstMainHeader::notificationMenuEvent', item) // eslint-disable-line
      if (item.click_action && item.click_action.length > '') {
        this.emitAction('notification', 'click', item)
      }
    },
    sanitizeData (notification) {
      if (notification && notification.title) {
        if (notification.title.length > 20) {
          var count = 0
          var titleData = notification.title.split(' ')
          notification.title = ''
          console.log('titleData', titleData) // eslint-disable-line
          titleData.forEach(ele => {
            notification.title += ele
            count += ele.length
            if (count >= 20) {
              notification.title += '<br>'
              count = 0
            } else {
              notification.title += ' '
            }
          })
        }
      }
      if (notification && notification.body) {
        if (notification.body.length > 20) {
          var bodyData = notification.body.split(' ')
          bodyData.forEach(ele => {

          })
        }
      }
    },
    pushNotification (notification) {
      this.sanitizeData(notification)
      this.notifications.push({
        ...notification,
        show_delete: false
      })
    },
    deleteAllNotification () {
      this.notifications = []
    },
    deleteNotification (index) {
      this.notifications.splice(index, 1)
    },
    clickEvent (link) {

    },
    handleHover (isHovered, item) {
      item.show_delete = isHovered
    },
    changeEnte (codiceEnte) {
      var self = this
      window.gestelVue.helperApi.cambioEnte(codiceEnte).then(function (json) {
        self.globalEventBus.$emit(GST_EVENT_UPDATE_ENTE, codiceEnte)
      })
    },

    loadEnti () {
      var self = this
      var request = {
        params: {
          tipo_operazione: 'MB.MEN.V.02',
          xmlOutPut: 'S',
          xFormMode: 'S'
        }
      }
      var config = {
        headers: {
          Accept: 'application/json',
          'cache-control': 'no-cache'
        },
        responseType: 'json'
      }

      window.gestelVue.http.get('', request, config)
        .then(function (response) {
          console.log('MB.MEN.V.02:', response) // eslint-disable-line
          if (response.data.PAGER.documento.opzioni.comboEnte) {
            if (response.data.PAGER.documento.opzioni.comboEnte instanceof Array) {
              response.data.PAGER.documento.opzioni.comboEnte.forEach(elem => {
                console.log('elem._attributes:', elem._attributes) // eslint-disable-line
                self.entiList.push(elem._attributes)
                if (response.data.PAGER.documento.comboEnte._text === elem._attributes.value) {
                  self.modelEnte = elem._attributes
                }
              })
            } else {
              console.log('elem._attributes:', response.data.PAGER.documento.opzioni.comboEnte._attributes) // eslint-disable-line
              self.entiList.push(response.data.PAGER.documento.opzioni.comboEnte._attributes)
              self.modelEnte = response.data.PAGER.documento.opzioni.comboEnte._attributes
            }
          }
          self.userName = response.data.PAGER.documento.Operatore._text
          self.loadingEnti = false
          self.emitAction('loaded', '', { result: 'success' })
        })
        .catch(function (err) {
          console.log('MB.MEN.V.02 err:', err) // eslint-disable-line
          self.loadingEnti = false
          self.emitAction('loaded', '', { result: 'failed' })
        })
    },
    emitAction (action, event, data) {
      this.$emit('callback', {
        action: action,
        event: event,
        data: data
      })
    }

  }
}
</script>
<style scoped>
.gst-main-header-sticky,
.gst-main-header {
  padding: 0px;
  margin: 0px;
  height: 60px;
}
.gst-main-header-sticky{
  position: sticky;
  top: 0;
  z-index: 1071;
}
.bg-primary {
  background-color: #336699!important;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.gst-main-header__nav_item_no_gutters {
  padding-right: 0.1125rem;
  padding-left: 0.1125rem;
}
.gst-main-header__nav_item{
  padding-right: .5rem;
  padding-left: .5rem;
}
.navbar {
  height: 53px;
  padding: 0;
  margin: 0;
  box-shadow: 0 0.25rem 0.25rem rgba(0,0,0,.25), inset 0 -1px 5px rgba(0,0,0,.25);
}
.navbar-brand {
  padding-top: 0.1125rem;
  padding-bottom: .1125rem;
}
.gst-main-header__nav_form {
  padding-top: .4em;
  padding-right: .5rem;
  padding-left: .5rem;
  width: 400px;
}
form.form-inline {
  width: 100%;
  margin: 0;
}
.gst-main-header__gst-multiselect_package_search,
.gst-main-header__gst-multiselect_enti_multiselect {
    width: 98%;
}
.gst-main-header__menu_icon {
  margin-top: .3em;
}
.gst-main-header__solo_icon {
  margin-top: .9em;
}
.gst-main-header__dropdown_icon {
  margin-top: .5em;
}
.gst-main-header__dropdown_icon_badge_notification {
  margin: 0 0 0 -0.9em;
  background: red;
  padding-right: .5em;
  padding-left: .4em;
  position: absolute;
}
.gst-main-header__dropdown_avatar {
  background: white;
  color: black;
  font-weight: 700;
}
input.multiselect__input:focus {
  padding: 8px 0 0 4px !important;
}
.gst-main-header__dropdown_notification-item-divider{
  margin-top: .3rem;
  margin-bottom: .3rem;
}
.dropdown-divider {
  margin: 0.2rem 0;
}
.dropdown-header {
  padding: .5rem .5rem 0 .5rem;
}
.dropdown-item {
  padding: .0rem .5rem .5rem .5rem !important;
}
.gst-main-header__dropdown_notification-item-title {
  font-weight: bolder;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.gst-main-header__dropdown_notification-item-title-action {
  display: inline-block;
  position: absolute;
  margin-left: -1rem;
}
.dropdown-menu-right.show {
  max-width: 300px !important;
}
.gst-main-header__dropdown_notification-item {
  min-width: 300px;
  max-width: 300px;
}
.gst-main-header__dropdown_notification-item-content {
  padding: 0.2rem .5rem .2rem .5rem;
  text-align: center;
}
</style>
