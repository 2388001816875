var authenticator = require('authenticator')

class AuthenticatorHelper {
  generateKey () {
    return authenticator.generateKey()
  }

  generateTotpUri (formattedKey, accountName, issuer) {
    return authenticator.generateTotpUri(
      formattedKey,
      accountName,
      issuer,
      'SHA1',
      6,
      30
    )
  }

  verifyToken (formattedKey, formattedToken) {
    return authenticator.verifyToken(formattedKey, formattedToken)
  }

  // var formattedToken = authenticator.generateToken(formattedKey);
  // "957 124"

  //
  // { delta: 0 }

  // authenticator.verifyToken(formattedKey, '000 000');
  // null

  // authenticator.generateTotpUri(formattedKey, "john.doe@email.com", "ACME Co", 'SHA1', 6, 30);
  //
  // otpauth://totp/ACME%20Co:john.doe@email.com?secret=HXDMVJECJJWSRB3HWIZR4IFUGFTMXBOZ&issuer=ACME%20Co&algorithm=SHA1&digits=6&period=30
}
export default new AuthenticatorHelper()
