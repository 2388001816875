import responseWSHelper from '../helpers/responsews.helper'

class StreetModel {
  constructor () {
    this.id = 0
    this.toponimo = ''
    this.nome = ''
    this.codice = ''
  }

  fromXmlToJson (data) {
    var list = []
    responseWSHelper.parse(data).then(() => {
      if (responseWSHelper.getCodiceMessaggio() === '200') {
        const indirizzi = responseWSHelper.getResult().Indirizzo
        indirizzi.forEach(element => {
          const street = new StreetModel()
          street.id = element.$.T10KEY
          street.toponimo = element.Toponimo[0]._
          street.nome = element.Strada[0]._
          street.codice = element.Strada[0].$.cod_strada
          list.push(street)
        })
      }
    })
    return list
  }

  toJsonAutoCompete (indirizzi) {
    var list = []
    indirizzi.forEach(element => {
      const street = new StreetModel()
      street.id = element.T10KEY
      street.toponimo = element.Toponimo.cod_toponimo
      street.nome = '(' + element.Strada.cod_strada + ') ' + element.DescrizioneStrada
      street.codice = element.Strada.cod_strada
      list.push(street)
    })

    return list
  }
}

export default new StreetModel()
