import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings.store'
import soggetti from './modules/soggetti.store'
import comuni from './modules/comuni.store'
import stradario from './modules/stradario.store'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    settings,
    soggetti,
    comuni,
    stradario
  }
})
