import { GST_EVENT_PUSH_MESSAGE } from '../types'
import helperApi from '../api/helper.api'

var firebase = require('firebase/app')
require('firebase/messaging')
require('firebase/analytics')

var firebaseConfig = {
  apiKey: 'AIzaSyCaHYHrXZ1DRvjaURz1UgCs_AgJ-mhwvYA',
  authDomain: 'gestel-cloud.firebaseapp.com',
  databaseURL: 'https://gestel-cloud.firebaseio.com',
  projectId: 'gestel-cloud',
  storageBucket: 'gestel-cloud.appspot.com',
  messagingSenderId: '640023651330',
  appId: '1:640023651330:web:d3ff5bf3e73bb9ba6bb31b',
  measurementId: 'G-3PNES26FWT'
}

class FirebaseHelper {
  init (eventBus) {
    var defaultApp = firebase.initializeApp(firebaseConfig)

    firebase.analytics(defaultApp)
    const messaging = firebase.messaging(defaultApp)

    messaging.usePublicVapidKey('BKB1oUoBCDu6sgfUORyk8Zg_7cb7fBNL-7wOUc3GyjgaP-Ye5dVY-AkgbWJPvKnqI3_KVpF33kYpZtL6esg4C7Q')
    // checks service worker support
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/mba01/firebase-messaging-sw.js')
        .then(function (registration) {
          // registration worked
          console.log('Registration succeeded. Scope is ' + registration.scope) // eslint-disable-line  
          messaging.useServiceWorker(registration)

          messaging.requestPermission().then(() => {
            console.log('<FirebaseHelper::init> Notification permission granted.') // eslint-disable-line  
            messaging.getToken().then((token) => {
              console.log('<FirebaseHelper::init> New token created: ', token) // eslint-disable-line
              helperApi.saveNotificationToken(token)
            }).catch((err) => {
              console.log('<FirebaseHelper::init> > Unable to get token.', err) // eslint-disable-line
            })
          }).catch((err) => {
            console.log('<FirebaseHelper::init> Unable to get permission to notify.', err) // eslint-disable-line
          })

          messaging.onTokenRefresh(function () {
            messaging.getToken().then(function (newToken) {
              console.log('<FirebaseHelper::init> Token refreshed: ', newToken) // eslint-disable-line
              helperApi.saveNotificationToken(newToken)
            }).catch(function (err) {
              console.log('<FirebaseHelper::init> Unable to retrieve refreshed token ', err) // eslint-disable-line
            })
          })

          messaging.onMessage(function (payload) {
            console.log('Received message. ', payload) // eslint-disable-line
            var notificationTitle = payload.notification.title
            var notificationOptions = {
              body: payload.notification.body,
              icon: payload.notification.icon,
              tag: payload.notification.tag,
              data: payload.notification/*,
              actions:
                [
                  {
                    action: 'action-id',
                    title: 'Title'
                    // icon: '/images/demos/action-1-128x128.png'
                  }
                ] */
            }
            console.log(notificationTitle, notificationOptions)  // eslint-disable-line

            eventBus.$emit(GST_EVENT_PUSH_MESSAGE, payload)
            return registration.showNotification(notificationTitle, notificationOptions)
          })
        }).catch(function (error) {
          // registration failed
          console.log('Registration failed with ' + error) // eslint-disable-line
        })
    }
  }
}

export default new FirebaseHelper()
