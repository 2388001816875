import axios from 'axios'
import oauthApi from './api/oauth.api.js'
/// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
/// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

let isRefreshing = false
let failedQueue = []
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
      console.log("Resolve");
    }
  })
  failedQueue = []
}

axios.interceptors.request.use(
  config => {
    console.log('Request Interceptor', config) // eslint-disable-line
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)


axios.interceptors.response.use(
  res => {
    console.log('Response Interceptor', res)
    return res;
  },
  error => {
    console.log("<Interceptor Axios Response> : Error", error)
    const originalRequest = error.config
    if (
      error.response.data.error === 'invalid_token' &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
            failedQueue.push({
              resolve,
              reject
            })
          }).then(token => {
            console.log("<Interceptor Axios Response> : Orginial Request", originalRequest)
            console.log('<Interceptor Axios Response> : Token: ', token)
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }
      originalRequest._retry = true
      isRefreshing = true
      return new Promise(function (resolve, reject) {
        oauthApi
          .fetchRefreshToken(window.localStorage.getItem("refreshToken"))
          .then(response => {
            console.log("<Interceptor Axios Response> : Refresh Token concluso", response);
            originalRequest.headers['Authorization'] =
              'Bearer ' + response.data.access_token
            window.localStorage.setItem("accessToken", response.data.access_token);
            window.localStorage.setItem("refreshToken", response.data.refresh_token);
            console.log("<Interceptor Axios Response> : Elabora Coda", failedQueue)
            processQueue(null, response.data.access_token)
            resolve(axios(originalRequest))
          }).catch(err => {
            console.log('Response Interceptor - err: ', err)
            processQueue(err, null)
            reject(err)
          }).then(() => {
            isRefreshing = false
          })
      })
    } else {
      console.log('Response Interceptor - error:', error)
    }
    return Promise.reject(error)
  }
)

export * from './stores'
export * from './install'
export * from './eventbus'
export * from './components'
export * from './plugins'
export * from './types'
export * from './api'
export * from './firebase'
