import stradarioApi from '../../api/stradario.api'

const state = {
  strade: null
}

const getters = {
  getListaStrade: state => {
    return state.strade
  }
}

const actions = {
  fetchStrade: (context, params) => {
    console.log('<stradario.fetchStrade> params:', params) // eslint-disable-line 
    stradarioApi.fetchStrade(params.nome, params.page, params.rowsPerPage)
      .then(data => context.commit('setListaStrade', data))
  }
}

const mutations = {
  setListaStrade: (state, strade) => {
    console.log('<stradario.setListaStrade> strade:', strade) // eslint-disable-line 
    state.strade = strade
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
