<template>
  <div class="gst-dialog" :id="id" :title="title">
    <slot></slot>
  </div>
</template>

<script>
import { eventBus } from '../../eventbus'
import { GST_EVENT_CLOSE_DIALOG } from '../../types'

let dialogStatus = ''

export default {
  name: 'GstDialog',
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    width: { type: Number, required: false },
    minWidth: { type: Number, required: false },
    minHeight: { type: Number, required: false },
    isModal: { type: Boolean, required: false },
    status: { type: String, required: false },
    isResizable: { type: Boolean, required: false },
    buttons: { type: Array, required: false }
  },
  data: function () {
    return {
      buttonsDialog: []
    }
  },
  watch: {
    status () {
      console.log('***GstDialog::updateDialog watch', this.status) // eslint-disable-line   
      this.updateDialog()
    }
  },
  created () {
    this.width = this.width || 800
    this.minWidth = this.minWidth || 600
    this.minHeight = this.minHeight || 100
    // this.isModal = this.isModal || true
    this.status = this.status || 'close'
    this.isResizable = this.isResizable || false
    this.buttons = this.buttons || []

    // callbackButton = this.callback
    this.buttons.forEach(element => {
      this.buttonsDialog.push({
        text: element.text,
        click: function () {
          element.callback()
        }
      })
    })
  },
  mounted () {
    console.log('***GstDialog::this.isModal', this.isModal) // eslint-disable-line     
    var self = this
    $('#' + this.id).dialog({ // eslint-disable-line
      beforeClose: function () {
        if (dialogStatus === 'open') {
          eventBus.$emit(GST_EVENT_CLOSE_DIALOG, self.id)
          return false
        } else {
          return true
        }
      },
      autoOpen: false,
      modal: this.isModal,
      resizable: this.isResizable,
      width: this.width,
      minWidth: this.minWidth,
      minHeight: this.minHeight,
      position: { my: 'center top', at: 'center top', of: '#gst-tabs-id' },
      show: { effect: 'clip', duration: 600 },
      hide: { effect: 'fade', duration: 800 },
      buttons: this.buttonsDialog
    })
    this.updateDialog()
  },
  beforeDestroy: function () {
    console.log('***GstDialog::destroyed') // eslint-disable-line     
    $("#" + this.id) // eslint-disable-line
      .dialog('destroy')
      .remove()
  },
  methods: {
    updateDialog () {
      console.log('***GstDialog::updateDialog this.status', this.status) // eslint-disable-line     
      if (this.status === 'open' || this.status === 'close') {
        dialogStatus = this.status
        $("#" + this.id).dialog(dialogStatus); // eslint-disable-line
      }
    }
  }
}
</script>

<style scoped>
</style>
