import comuniApi from '../../api/comuni.api'

const state = {
  comuni: null,
  comuneSelezionato: null
}

const getters = {
  getComuni: state => {
    return state.comuni
  },
  getComuneSelezionato: state => {
    return state.comuneSelezionato
  }
}

const actions = {
  fetchComuni: (context, params) => {
    console.log('<comuni.fetchComuni> params:', params) // eslint-disable-line  
    comuniApi.fetchComuni(params.comune, params.page, params.rowsPerPage)
      .then(data => context.commit('setComuni', data))
  }
}

const mutations = {
  setComuni: (state, comuni) => {
    state.comuni = comuni
  },
  setComuneSelezionato: (state, comuneSelezionato) => {
    state.comuneSelezionato = comuneSelezionato
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
