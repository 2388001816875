<template>
  <div class="gst-grid" style="overflow: auto;">
    <table id="gst-grid-table" :class="tableClass">
      <tr>
        <template v-for="field in fields">
          <slot name='header' v-bind:item="field">
            <th v-if="!field.disabled" :key="field.key" :style="tdStyle(field.alignTitle)">
              <slot :name="`header.${field.key}`" v-bind:item="field">
                {{ field.label }}
              </slot>
            </th>
          </slot>
        </template>
      </tr>
      <slot name='body.prepend' v-bind:items="getItems"></slot>
      <tr v-for="(item, index) in getItems" :key="item.id" @click="click(item)" @dblclick="dblclick(item)">
        <template v-for="field in fields">
          <td v-if="!field.disabled" :key="field.key" :style="tdStyle(field.align)" @click="clickField($event, index, item, field.key)">
            <span data-toggle="tooltip" :title="item[field.tooltip]">
              <template v-if="field.type && field.type === 'checkbox' && item[field.key] != undefined">
                <input type="checkbox" name="input-checkbox" id="checkbox"
                :value="item[field.key]" v-model="item[field.key]"
                @change="changeEvent($event, index, item, field.key)">
              </template>
              <template v-else-if="field.type && field.type === 'link' && item[field.key] != undefined">
                <a href="#">{{ item[field.key] }}</a>
              </template>
              <template v-else-if="field.type && field.type === 'button' && item[field.key] != undefined">
                <gst-button :id="index" width="auto"
                  :title="item[field.button_tooltip]"
                  :text="item[field.key]"
                  :icon="item[field.button_icon]"
                  @click="buttonItemClick(index, item, field.key)"></gst-button>
              </template>
              <template v-else-if="field.type && field.type === 'img' && item[field.key] != undefined">
                <img :src="item[field.key]" :width="field.width" :height="field.height" />
              </template>
              <template v-else-if="field.type && field.type === 'icon' && item[field.key] != undefined">
                <span v-if="item[field.key].length > 0" class="material-icons">{{ item[field.key] }}</span>
              </template>
              <template v-else >
                <span v-html="item[field.key]"> </span>
              </template>
            </span>
          </td>
        </template>
      </tr>
      <slot name='body.append' v-bind:items="getItems">
      </slot>
     </table>
    <template v-if="paginationType === 'default'">
      <div class="gst-grid-box-footer" v-if="showEmptyFooter || showItemsFind || getItems.length > 0">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-content-center flex-wrap">
            <template v-if="getItems.length > 0">
              <span id="footer-text-goto-page" class="gst-text-info-footer">Vai alla pagina:</span>
              <gst-input label="" styletype="inline" inputwidth="80px" labelwidth="auto" mask="number" :size="sizejumpPage" v-model="jumpPage"></gst-input>
              <gst-button id="btnJump" width="auto" title="Vai alla pagina" text="" icon="transfer-e-w" state="enabled" @click="btnJumpClick()"></gst-button>
            </template>
          </div>
          <div class="d-flex align-content-center flex-wrap">
            <template v-if="showItemsFind || getItems.length > 0">
              <span id="footer-text-elements-find" class="gst-text-info-footer">Elementi Trovati: {{pagination.totalRows}}</span>
            </template>
          </div>
          <div class="d-flex align-content-center flex-wrap">
            <template v-if="getItems.length > 0">
              <span id="footer-text-navigation-row" class="gst-text-info-footer">Righe per pagina:</span>
              <gst-combobox label="" @change="itemSelected" :value="itemsRowsPerPageSelected" :items="itemsRowsPerPage" field="description" styletype="inline"></gst-combobox>
              <span id="footer-text-navigation-pages" class="gst-text-info-footer">Pagina {{pagination.page}} di {{totalPages}}</span>
              <gst-button id="btnFirst" width="auto" title="Vai alla prima pagina" text="" icon="seek-first" :state="btnPrevState" @click="btnSeekFirstClick()"></gst-button>
              <gst-button id="btnPrev" width="auto" title="Vai alla pagina precedente" text="" icon="seek-prev" :state="btnPrevState" @click="btnSeekPrevClick()"></gst-button>
              <gst-button id="btnNext" width="auto" title="Vai alla pagina successiva" text="" icon="seek-next" :state="btnNextState" @click="btnSeekNextClick()"></gst-button>
              <gst-button id="btnEnd" width="auto" title="Vai all'ultima pagina" text="" icon="seek-end" :state="btnNextState" @click="btnSeekEndClick()"></gst-button>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="paginationType === 'step'">
      <div class="gst-grid-box-footer" v-if="showEmptyFooter || getItems.length > 0">
        <div class="d-flex justify-content-end">
          <div class="d-flex align-content-center flex-wrap" v-if="getItems.length > 0">
            <span id="footer-text-navigation-row" class="gst-text-info-footer">Righe per pagina:</span>
            <gst-combobox label="" @change="itemSelected" :value="itemsRowsPerPageSelected" :items="itemsRowsPerPage" field="description" styletype="inline"></gst-combobox>
            <span id="footer-text-navigation-pages" class="gst-text-info-footer">Pagina {{pagination.page}} di {{totalPages}}</span>
            <gst-button id="btnFirst" width="auto" title="Vai alla prima pagina" text="" icon="seek-first" :state="btnPrevState" @click="btnSeekFirstClick()"></gst-button>
            <gst-button id="btnPrev" width="auto" title="Vai alla pagina precedente" text="" icon="seek-prev" :state="btnPrevState" @click="btnSeekPrevClick()"></gst-button>
            <gst-button id="btnNext" width="auto" title="Vai alla pagina successiva" text="" icon="seek-next" :state="btnNextState" @click="btnSeekNextClick()"></gst-button>
            <gst-button id="btnEnd" width="auto" title="Vai all'ultima pagina" text="" icon="seek-end" :state="btnNextState" @click="btnSeekEndClick()"></gst-button>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="paginationType === 'none'">
      <div class="gst-grid-box-footer" v-if="showEmptyFooter || getItems.length > 0">
        <div class="d-flex justify-content-center">
          <div class="d-flex align-content-center flex-wrap" v-if="showItemsFind || getItems.length > 0">
            <span id="footer-none-text-elements-find" class="gst-text-info-footer">Elementi Trovati: {{pagination.totalRows}}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="paginationType === 'empty'">
      <div class="gst-grid-box-footer" v-if="showEmptyFooter"></div>
    </template>
  </div>
</template>

<script>
import GstButton from './GstButton.vue'
import GstCombobox from './GstCombobox.vue'
import GstInput from './GstInput.vue'

export default {
  name: 'GstGrid',
  components: {
    'gst-button': GstButton,
    'gst-combobox': GstCombobox,
    'gst-input': GstInput
  },
  props: {
    fields: { type: Array, required: true },
    items: { type: Array, required: true },
    showItemsFind: { type: Boolean, required: false, default: false },
    showEmptyFooter: { type: Boolean, required: false, default: false },
    paginationType: { type: String, required: false, default: 'default' },
    pagination:
    {
      page: { type: Number, required: false, default: 1 },
      rowsPerPage: { type: Number, required: false, default: 10 },
      totalRows: { type: Number, required: false, default: 0 }
    }
  },
  data () {
    return {
      btnNextState: 'enabled',
      btnPrevState: 'enabled',
      itemsRowsPerPageSelected: {},
      itemsRowsPerPage: [
        { id: 1, value: 10, description: '10' },
        { id: 2, value: 15, description: '15' },
        { id: 3, value: 20, description: '20' },
        { id: 4, value: 40, description: '40' },
        { id: 5, value: 60, description: '60' },
        { id: 6, value: 80, description: '80' }
      ],
      jumpPage: ''
    }
  },
  created () {
    if (this.paginationType !== 'default' &&
        this.paginationType !== 'step' &&
        this.paginationType !== 'none' &&
        this.paginationType !== 'empty') {
      this.paginationType = 'default'
    }

    if (this.pagination) {
      this.itemsRowsPerPageSelected = this.itemsRowsPerPage.find((item) => {
        return item.value === this.pagination.rowsPerPage
      })
    }
    console.log('paginationType', this.paginationType) // eslint-disable-line     
    console.log('showEmptyFooter', this.showEmptyFooter) // eslint-disable-line     
    console.log('showItemsFind', this.showItemsFind) // eslint-disable-line     
    this.updatedPagination()
  },
  watch: {
    items () {
      this.updatedPagination()
    },
    showEmptyFooter (value) {
      console.log('watch** showEmptyFooter', value) // eslint-disable-line     
    },
    showItemsFind (value) {
      console.log('watch** showItemsFind', value) // eslint-disable-line     
    }
  },
  computed: {
    getItems () {
      let list = []
      if (Array.isArray(this.items)) {
        list = this.items
      } else {
        list.push(this.items)
      }
      return list
    },
    tableClass () {
      return this.paginationType === 'empty' ? 'gst-grid-table-solo' : 'gst-grid-table-default'
    }
  },
  methods: {
    btnJumpClick () {
      if (this.pagination && this.jumpPage !== '') {
        if (this.jumpPage >= 1 && this.jumpPage <= this.totalPages) {
          this.pagination.page = this.jumpPage
          this.sendPaginationEvent(this.pagination)
        } else {
          // error
        }
      }
      this.jumpPage = ''
    },
    btnSeekFirstClick () {
      if (this.pagination) {
        this.pagination.page = 1
        this.sendPaginationEvent(this.pagination)
      }
    },
    btnSeekEndClick () {
      if (this.pagination) {
        this.pagination.page = this.totalPages
        this.sendPaginationEvent(this.pagination)
      }
    },
    btnSeekPrevClick () {
      if (this.pagination) {
        this.pagination.page--
        this.sendPaginationEvent(this.pagination)
      }
    },
    btnSeekNextClick () {
      if (this.pagination) {
        this.pagination.page++
        this.sendPaginationEvent(this.pagination)
      }
    },
    itemSelected (item) {
      if (this.pagination) {
        this.itemsRowsPerPageSelected = item
        this.pagination.rowsPerPage = item.value
        this.sendPaginationEvent(this.pagination)
      }
    },
    sendPaginationEvent (value) {
      this.$emit('change', value)
    },
    updatedPagination () {
      if (this.pagination) {
        this.totalPages = Math.floor(this.pagination.totalRows / this.pagination.rowsPerPage)
        if ((this.pagination.totalRows % this.pagination.rowsPerPage) !== 0) {
          this.totalPages++
        }
      }
      this.btnNextState = (this.pagination && this.totalPages && this.totalPages === this.pagination.page) ? 'disabled' : 'enabled'
      this.btnPrevState = (this.pagination && this.pagination.page === 1) ? 'disabled' : 'enabled'
      this.sizejumpPage = (this.pagination && this.totalPages) ? this.totalPages.toString().length : 2
    },
    tdStyle (align) {
      return 'text-align: ' + align + '; font-size: 12px; font-family: arial, helvetica, sans-serif;'
    },

    click (item) {
      this.$emit('click', item)
    },
    dblclick (item) {
      this.$emit('dblclick', item)
    },
    changeEvent (event, index, item, key) {
      this.$emit('change', { index: index, key: key, value: event.target.value, item: item })
    },
    clickField (event, index, item, key) {
      this.$emit('fieldclick', { index: index, key: key, value: event.target.value, item: item })
    },
    buttonItemClick (index, item, key) {
      this.$emit('button-click', { index: index, key: key, item: item })
    }
  }
}
</script>

<style scoped>
@import '../../assets/styles/gst.panel.style.css';

#gst-grid-table,
.gst-grid-table-default {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  border: none;
  border-collapse: collapse;
  border-color: currentcolor;
  border-style: none;
  border-width: medium;
  -webkit-border-top-left-radius: 9px;
  -webkit-border-top-right-radius: 9px;
  -moz-border-radius-topleft: 9px;
  -moz-border-radius-topright: 9px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.gst-grid-table-solo {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  border: none;
  border-collapse: collapse;
  border-color: currentcolor;
  border-style: none;
  border-width: medium;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

#gst-grid-table td {
  cursor: pointer;
}

#gst-grid-table td, #gst-grid-table th {
  border: 1px solid #faf9f9;
  padding: 8px;
}

#gst-grid-table tr:nth-child(even){background-color: #cfcfdc;}
#gst-grid-table tr:nth-child(odd){background-color: #e2e2e8;}
#gst-grid-table tr:hover {background-color: #a6c0d4;}

#gst-grid-table th {
  text-align: left;
  background-color: #336699;
  color: #e2e2e8;
}

.gst-grid-box-footer {
    padding-top: 3px;
    padding-right: 8px;
    padding-left: 8px;
    height: 40px;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    margin-top: 0px;
    margin-bottom: 0px;
    background: #cfcfdc;
    border-top: 3px solid #336699;
    border-bottom: 3px solid #336699;
    border-radius:0px 0px 5px 5px;
    margin-left: 0.8px;
    margin-right: 0.8px;
}

.gst-text-info-footer {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    display: inline-block;
}

span#footer-text-elements-find {
  margin-top: 1px;
}
span#footer-none-text-elements-find {
  margin-top: 10px;
}
span#footer-text-navigation-row,
span#footer-text-navigation-pages,
span#footer-text-goto-page {
  margin-top: 5px;
}
a {
  color: #007bff !important;
}
</style>
