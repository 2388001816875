<template>
  <div class="gst-authenticator">
      <ol v-if="isGenQrcode === true">
        <li>
          <strong>Apri</strong> l’app di autenticazione.
          <a href="#" @click="showInfoApp = !showInfoApp">
            Hai bisogno di un'app?</a>
            <div v-if="showInfoApp === true" class="panel panel-default">
              <div class="panel-body" style="padding-left:30px !important;">
                <p>&nbsp;</p>
                <hr>
                <p>Download di un'app di autenticazione: </p>
                <p>Le app di autenticazione generano codici casuali che puoi utilizzare
                per accedere. Non hanno accesso alla tua password Operatore o alle
                informazioni sul tuo account. Puoi scegliere l'app quella che
                preferisci.</p>
                <p><strong>Per il download:</strong></p>
                <p>a. Accedi all'App Store del tuo telefono.</p>
                <p>b. Cerca "App di autenticazione".</p>
                <p>c. Scarica un'app.(Es.: Google Authenticator, Microsoft Authenticator)</p>
                <hr>
                <p>&nbsp;</p>
              </div>
            </div>
        </li>
        <li>
          <strong>Aggiungi</strong> un account all'interno dell'app e
          acquisisci il codice a barre riportato di seguito.
        </li>
        <vue-qrcode v-if="totpUri" :value="totpUri" />
        <li>
          <strong>Inserisci OTP.</strong> Dopo aver acquisito il codice a
          barre, inserisci il codice OTP generato dall'app:
        </li>
      </ol>
      <p v-else>
        Inserisci il codice OTP generato dall'App di autenticazione precedentemente associata
      </p>
      <form class="form-inline">
        <div class="form-group">
          <input class="form-control" value="" type="text" maxlength="6" required v-model="opt" />
          <button :disabled="isDisabled" type="button" class="btn btn-default" style="margin-left:10px;" @click="verificaOTP">
            <i class="icon-ok" style="color: #33CC33;"></i>
            <span style="margin-left:10px;"><b>{{btnLable}}<b></span>
          </button>
        </div>
        <span v-if="error_message" style="color: red; padding-left:5px;">{{error_message}}</span>
      </form>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import authenticatorHelper from '@/helpers/authenticator.helper'

export default {
  name: 'GstAuthenticator',
  components: { VueQrcode },
  props: {
    authenticatorkey: {
      type: String,
      default: null
    },
    username: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      opt: '',
      error_message: null,
      btnLable: null,
      isGenQrcode: false,
      showInfoApp: false
    }
  },
  created () {
    console.log('<GstAuthenticator::created> authenticatorkey:', this.authenticatorkey) // eslint-disable-line
    console.log('<GstAuthenticator::created> username:', this.username) // eslint-disable-line
    if (this.authenticatorkey === null) {
      this.isGenQrcode = true
      this.btnLable = 'Verifica e Ettiva OTP'
      this.formattedKey = authenticatorHelper.generateKey()
    } else {
      this.btnLable = 'Verifica OTP'
      this.formattedKey = this.authenticatorkey
    }
  },
  computed: {
    isDisabled: function () {
      return (this.opt.length !== 6)
    },
    totpUri: function () {
      console.log('<GstAuthenticator::computed> username:', this.username)  // eslint-disable-line   
      return this.username == null ? '' : authenticatorHelper.generateTotpUri(
        this.formattedKey,
        this.username,
        'Sintax srl'
      )
    }
  },
  methods: {
    verificaOTP: function () {
      const formattedToken = this.opt.substring(0, 3) + ' ' + this.opt.substring(3, 6)
      console.log('<GstAuthenticator::verificaOTP> formattedToken:', formattedToken)  // eslint-disable-line   
      console.log('<GstAuthenticator::verificaOTP> formattedKey:', this.formattedKey)  // eslint-disable-line   

      const result = authenticatorHelper.verifyToken(
        this.formattedKey,
        formattedToken
      )
      console.log('<Authenticator::verify> result:', result) // eslint-disable-line   
      if (result === null) {
        this.error_message = 'OTP non valido'
      } else {
        const date = new Date()
        this.$emit('input', this.formattedKey + '@' + formattedToken + '@' + date.toUTCString())
      }
    }
  }
}

</script>
