import axios from 'axios'

class InterventiApi {
  async fetchInterventi (dataInizio, dataFine, cimasa, codstr, operatore, stato) {
    console.log('<InterventiApi.fetchInterventi> ') // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> dataInizio', dataInizio) // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> dataFine', dataFine) // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> cimasa', cimasa) // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> strada', codstr) // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> operaio', operatore) // eslint-disable-line
    console.log('<InterventiApi.fetchInterventi> stato', stato) // eslint-disable-line
    const request = {
      params: {
        tipo_operazione: 'AF.CRSL.WS.01',
        startData: dataInizio,
        endData: dataFine,
        cimasa: cimasa,
        codstr: codstr,
        operatore: operatore,
        stato: stato
      }
    }

    const config = {
      headers: {
        'Accept': 'application/xml', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'json'
    }
    console.log('<InterventiApi.fetchInterventi> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)

    /* remove comment */
    var data = result.data
    const iStart = result.data.indexOf('<!--')
    const iEnd = result.data.indexOf('-->')
    if (iEnd > iStart) {
      data = result.data.substring(iEnd + 4, result.data.length)
    }

    /* conver data to json */
    return data
  }

  async fetchComboOperai () {
    const request = {
      params: {
        tipo_operazione: 'AF.LSOP.WS.01'
      }
    }

    const config = {
      headers: {
        'Accept': 'application/xml', // eslint-disable-line
        'cache-control': 'no-cache'
      },
      responseType: 'xml'
    }
    console.log('<InterventiApi.fetchComboOperai> axios.defaults.headers: ', axios.defaults.headers) // eslint-disable-line
    const result = await axios.get('', request, config)

    /* remove comment */
    var data = result.data
    const iStart = result.data.indexOf('<!--')
    const iEnd = result.data.indexOf('-->')
    if (iEnd > iStart) {
      data = result.data.substring(iEnd + 4, result.data.length)
    }

    /* conver data to json */
    return data
  }
}

export default new InterventiApi()
